const TOKEN_KEY = "auth-token"

import { getCookie, setCookie, removeCookie } from "@/utils/cookie"
import { setStorage } from "@/utils/storage"
import api from "@/api"
const Base64 = require("js-base64").Base64

const state = {
	user: {
		token: getCookie(TOKEN_KEY),
	}
}

const mutations = {
	SET_TOKEN(state, token) {
		state.user.token = token;
		setCookie(TOKEN_KEY, token)
	}
}

const actions = {
	login({commit},{account, password, rememberMe} = {}) 
	{
		return new Promise((resolve, reject) => 
		{
			api.auth.administrator.login(account, password)
			.then(response => {
				if(response.code === "0") {
					// 用户登录成功之后，将服务器返回的token数据缓存到store中	
					if(rememberMe)
					{
						let base64Password = Base64.encode(password); // base64加密
						setStorage("rememberMe", rememberMe)
						setStorage("account", account)
						setStorage("password", base64Password)
					}

					commit("SET_TOKEN", response.data)
				}
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	},
	logout({commit}) {
		return new Promise(resolve => {
			commit("SET_TOKEN", "");
			removeCookie(TOKEN_KEY);
			resolve();
		});
	},
	validateToken() {
		return new Promise((resolve, reject) => {
			api.auth.administrator.validateToken()
			.then(response => {
				resolve(response)
			})
			.catch(error => {
				reject(error)
			})
		})
	}
}

export {
	state,
	mutations,
	actions
}