<template>
    <div>
        <div class="toolbar">
            <el-button type="primary" plain @click="handleAdd">创建活动</el-button>
            <el-button type="danger" plain @click="handleRemove">删除活动</el-button>
            <el-input placeholder="按活动名称模糊查询" v-model="search.name">
                <el-button slot="append" icon="el-icon-search" @click="handleFindAll"></el-button>
            </el-input>
        </div>
		<el-table	
			:data="activityList"
			border
			stripe
			class="activityList"
			row-key="id"
			@selection-change="handleSelectionChange"
		>
			<el-table-column width="50px" label="序" align="center">
				<template>
					<i class="el-icon-rank" style="cursor: pointer; color: #E6A23C; font-size: 22px;" />
				</template>
			</el-table-column>
			<el-table-column type="selection" width="55px" align="center" />
			<el-table-column label="活动名称" prop="name" />
			<el-table-column label="库存" prop="inventory" width="100px" align="center"/>
			<el-table-column label="创建时间" prop="createTime" width="170px" align="center" />
			<el-table-column label="排序" prop="sort" width="80px" align="center" />
			<el-table-column label="上下架" width="100px" align="center">
				<template slot-scope="scope">
					<el-button 
						v-if="!scope.row.enabled" 
						type="success" 
						icon="el-icon-upload2" 
						size="small"
						@click="handleChangeEnabled(scope.row)">上架</el-button>
					<el-button 
						v-else 
						type="danger" 
						icon="el-icon-download" 
						size="small"
						@click="handleChangeEnabled(scope.row)">下架</el-button>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="150px" align="center">
				<template slot-scope="scope">
					<el-button type="primary" 
						icon="el-icon-edit" 
						circle
						@click="handleEdit(scope.row)">
					</el-button>
					<el-button type="danger" 
						icon="el-icon-delete" 
						circle 
						@click="handleRemoveSingle(scope.row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:current-page="paging.pageIndex"
			:page-sizes="[100, 200, 300, 400]"
			:page-size="paging.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="paging.recordCount" 
			class="paging"
			@current-change="handlePageIndexChanged"
			@size-change="handleSizeChange"
		/>
		<edit 
			:visible="showEditDialog" 
			:action="action" 
			ref="edit"
			@closeEditDialog="showEditDialog=false" 
			@refresh="handleRefresh"
		/>
    </div>
</template>

<script>
import sortable from 'sortablejs'
import edit from './components/edit.vue'
export default {
    page: {
        title: "活动管理",
    },
	components: {
		edit
	},
    data() {
        return {
			showEditDialog: false,
			action: "create",
			search: {
				name: "",
			},
			editActivity: {},
			activityList: [],
			sortedActivityList: [],
			paging: {
				pageIndex: 1,
				pageSize: 100,
				recordCount: 0,
				pageCount: 0
			},
			selected: [],
		};
    },
    mounted() {
		this.handleFindAll()
		this.rowDrop()
	},
	methods: {
		rowDrop() {
			const tbody = document.querySelector('.el-table__body-wrapper tbody')
			let that = this
			sortable.create(tbody, {
				onEnd({ newIndex, oldIndex }) {
					if(newIndex !== oldIndex) {
						that.sortedActivityList = []
						that.sortedActivityList.push(that.activityList[oldIndex])
						that.sortedActivityList.push(that.activityList[newIndex])

						that.$api.home.activity.transposition(that.sortedActivityList)
						.then(response => {
							if(response.code === '0') {
								that.handleFindAll()
							}
							else {
								that.$message.error(response.message)
							}
						})
					}
				}
			})
		},
		handleAdd() {
			this.action = "create"
			this.showEditDialog = true;
			this.$refs.edit.handleResetForm();
		},
		handleEdit(activity) {
			this.$api.home.activity.getSingle(activity.id)
			.then(response => {
				if(response.code === "0") {
					this.showEditDialog = true
					this.action = "edit"
					this.$refs.edit.handleEdit(response.data);
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleRefresh() {
			this.showEditDialog = false;
			this.handleFindAll();
		},
		handleFindAll() {
			this.activityList = []
			this.$api.home.activity.findAll(this.search.name, this.paging.pageIndex, this.paging.pageSize)
			.then(response => {
				if(response.code === "0") {
					this.activityList = response.data.activityList
					this.paging = response.data.paging
				}
			})
		},
		handleChangeEnabled(activity) {
			this.$api.home.activity.changeEnabled(activity.id, !activity.enabled)
			.then(response => {
				if(response.code === "0") {
					activity.enabled = !activity.enabled;
					this.$message.success(activity.enabled?'已上架':'已下架');
				}
				else {
					this.$message.error(`${activity.enabled?'上架':'下架'}失败：${response.message}`);
				}
			})
		},
		handleSelectionChange(val) {
			this.selected = val
		},
		handleRemoveSingle(activity) {
			this.selected = []
			this.selected.push(activity)

			this.handleRemove()
		},
		handleRemove() {
			if(this.selected.length == 0) {
				this.$message('请选择您要删除的活动');
				return
			}

			this.$confirm('您确定要删除选中的活动么?', '提示', 
			{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => 
			{
				this.$api.home.activity.remove(this.selected)
				.then(response => {
					if(response.code === "0") {
						this.$message.success('已删除')
						this.handleFindAll()
					}
					else {
						this.$message.error(response.message)
					}
				})
			})
			.catch(() => {});
		},
		handlePageIndexChanged(currentPageIndex) {
			this.paging.pageIndex = currentPageIndex;
			this.handleFindAll();
		},
		handleSizeChange(currentPageSize) {
			this.paging.pageSize = currentPageSize;
			this.paging.pageIndex = 1;
			this.handleFindAll();
		},
	}
};
</script>

<style scoped>
.toolbar .el-input-group {
    margin-left: 10px;
    width: 300px;
}
.activityList {
	margin-top: 1rem;
}
.paging {
	margin: 1rem auto;
    text-align: center;
}
</style>