import request from "@/utils/request"

export default {
	add(snowflake) {
		return request({
			url: `/api/home/snowflake/add`,
			method: "post",
			data: snowflake,
			options: {
                loading: {
					show: true,
					message: "正在保存，请稍候..."
				}
			}
		});
	},
	remove(snowflakeList) {
		return request({
			url: `/api/home/snowflake/remove`,
			method: "post",
			data: snowflakeList,
			options: {
                loading: {
					show: true,
					message: "正在删除，请稍候..."
				}
			}
		});
	},
	edit(snowflake) {
		return request({
			url: `/api/home/snowflake/edit`,
			method: "post",
			data: snowflake,
			options: {
                loading: {
					show: true,
					message: "正在保存，请稍候..."
				}
			}
		});
	},
	findAll(title, pageIndex, pageSize) {
		let queryString = `title=${title}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/home/snowflake/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取活动列表，请稍候..."
				}
			}
		});	
	},
	transposition(snowflakeList) {
		return request({
			url: `/api/home/snowflake/transposition`,
			method: "post",
			data: snowflakeList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	}
}