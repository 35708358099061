<template>
	<div>
		<el-dialog
            title="代注册会员信息"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="500px"
        >
			<el-form
                label-width="120px"
                :rules="formRules"
                :model="user"
                ref="user"
            >
				<el-form-item label="手机号码" prop="phoneNumbers">
                    <el-input
                        v-model="user.phoneNumbers"
                        auto-complete="off"
                        placeholder="手机号码"
                    />
                </el-form-item>
				<el-form-item label="会员级别">
					<el-select v-model="user.grade" placeholder="请选择">
						<el-option label="普通会员" value="normal" />
						<el-option label="VIP" value="VIP" />
					</el-select>
				</el-form-item>
				<el-form-item label="备注">
					<el-input type="textarea" :rows="4" v-model="user.adminRemark"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
                <el-button type="primary" plain @click="handleSave">保存</el-button>
                <el-button plain @click="handleClose">取消</el-button>
            </div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
        visible: {
            type: Boolean,
            required: true,
        }
    },
	data() {
		return {
			formRules: {
				phoneNumbers: [{required: true, message: "请输入手机号码", trigger: "blur"}],
			},
			user: {
				phoneNumbers: '',
				grade: 'normal',
				adminRemark: ''
			}
		}
	},
	methods: {
		handleClose() {
            this.$emit("closeDialog");
        },
		handleSave() {
			this.$refs.user.validate((valid) => {
                if(valid) {
                    this.$api.auth.user.addUser(this.user)
					.then(response => {
						if(response.code === '0') {
							this.handleResetForm();
							this.handleParentRefresh();
							this.$message.success('已保存')
						}
						else {
							this.$message.error(response.message)
						}
					})
                }
            });
		},
		handleResetForm() {
			this.user = {
				phoneNumbers: '',
				grade: 'normal',
				adminRemark: ''
			}
		},
		handleParentRefresh() {
			this.$emit("refresh");
		},
	}
}
</script>