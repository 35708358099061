<template>
	<div>
		<el-dialog
            title="材料单编辑"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="1000px"
        >	
			<div class="toolbar">
				<el-button type="primary" plain @click="handleAdd">添加材料</el-button>
				<el-button type="danger" plain @click="handleRemove">删除材料</el-button>
			</div>
			<el-table	
				:data="materialList"
				border
				stripe
				class="materialList"
				row-key="id"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55px" align="center" />
				<el-table-column label="材料名" prop="name" />
				<el-table-column label="链接" prop="href" />
				<el-table-column label="操作" width="150px" align="center">
					<template slot-scope="scope">
						<el-button type="primary" 
							icon="el-icon-edit" 
							circle
							@click="handleEdit(scope.row)">
						</el-button>
						<el-button type="danger" 
							icon="el-icon-delete" 
							circle 
							@click="handleRemoveSingle(scope.row)">
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<fieldset style="border: solid 1px #EBEEF5;" v-if="action !== ''">
				<legend>编辑材料单</legend>
				<el-form
					label-width="80px"
					:rules="formRules"
					:model="material"
					ref="material"
					class="formEdit"
				>
					<el-form-item label="名称" prop="name">
						<el-input
							v-model="material.name"
							auto-complete="off"
							placeholder="名称"
						/>
					</el-form-item>
					<el-form-item label="链接" prop="href">
						<el-input
							v-model="material.href"
							auto-complete="off"
							placeholder="链接"
						/>
					</el-form-item>
					<el-form-item label="排序" prop="sort" v-if="action !== 'create'">
						<el-input-number
							v-model="material.sort"
							:min="0"
						/>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" v-if="action==='create'" @click="handleSave">保存</el-button>
						<el-button v-else type="success" plain @click="handleSaveUpdate">修改</el-button>
						<el-button @click="handleCancel">取消</el-button>
					</el-form-item>
				</el-form>
			</fieldset>
			
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		visible: {
            type: Boolean,
            required: true,
        }
	},
	data() {
		return {
			materialList: [],
			action: '',
			photo: {},
			material: {
				photo: {
					id: ''
				},
				name: '',
				href: '',
				sort: 0
			},
			formRules: {
				name: [{required: true, message: "请输入名称", trigger: "blur"}],
				href: [{required: true, message: "请输入链接地址", trigger: "blur"}],
			},
			selected: []
		}
	},
	methods: {
		handleAdd() {
			this.action = 'create'
		},
		handleClose() {
			this.$emit("closeEditDialog");
		},
		handleResetForm() {
			this.action = ''
			this.material = {
				photo: {
					id: ''
				},
				name: '',
				href: '',
				sort: 0
			}
		},
		handleSave() {
			this.$refs.material.validate((valid) => {
                if(valid) {
					this.material.photo = this.photo

					this.$api.focus.photoWallMaterial.add(this.material)
					.then(response => {
						if(response.code === '0') {
							this.$message.success('已保存')
							this.handleResetForm()
							this.handleFindAll()
						}
						else {
							this.$message.info(response.message)
						}
					})
				}
			})
		},
		handleSaveUpdate() {
			this.$refs.material.validate((valid) => {
                if(valid) {
					this.$api.focus.photoWallMaterial.edit(this.material)
					.then(response => {
						if(response.code === '0') {
							this.$message.success('已保存')
							this.handleResetForm()
							this.handleFindAll()
						}
						else {
							this.$message.info(response.message)
						}
					})
				}
			})
		},
		handleCancel() {
			this.handleResetForm()
		},
		handleEditPhotoWallMaterial(photo) {
			this.photo = photo
			this.handleFindAll()
		},
		handleFindAll() {
			this.$api.focus.photoWallMaterial.findAll(this.photo.id)
			.then(response => {
				if(response.code === '0') {
					this.materialList = response.data
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		handleEdit(material) {
			this.action = 'edit';
			this.material = material;
		},
		handleRemoveSingle(material) {
			this.selected = []
			this.selected.push(material)

			this.handleRemove()
		},
		handleSelectionChange(val) {
			this.selected = val
		},
		handleRemove() {
			if(this.selected.length == 0) {
				this.$message('请选择您要删除的材料');
				return
			}

			this.$confirm('您确定要删除选中的材料么?', '提示', 
			{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => 
			{
				this.$api.focus.photoWallMaterial.remove(this.selected)
				.then(response => {
					if(response.code === "0") {
						this.$message.success('已删除')
						this.handleResetForm()
						this.handleFindAll()
					}
					else {
						this.$message.error(response.message)
					}
				})
			})
			.catch(() => {});
		},
	}
}
</script>

<style scoped>
.toolbar .el-input-group {
    margin-left: 10px;
    width: 300px;
}

.materialList {
	margin-top: 1rem;
}

.formEdit {
	width: 50%;
	margin-top: 1rem;
}
</style>