<template>
	<div>
		<el-form
                label-width="120px"
                :rules="formRules"
                :model="about"
                ref="about"
				style="width:60%"
            >

			<el-form-item label="微信" prop="wechat">
				<el-input
					v-model="about.wechat"
					auto-complete="off"
					placeholder="微信"
				/>
			</el-form-item>
			<el-form-item label="公众号" prop="mpwechat">
				<el-input
					v-model="about.mpwechat"
					auto-complete="off"
					placeholder="公众号"
				/>
			</el-form-item>
			<el-form-item label="抖音" prop="douyin">
				<el-input
					v-model="about.douyin"
					auto-complete="off"
					placeholder="抖音"
				/>
			</el-form-item>
			<el-form-item label="邮件" prop="email">
				<el-input
					v-model="about.email"
					auto-complete="off"
					placeholder="邮件"
				/>
			</el-form-item>
			<el-form-item label="地址" prop="address">
				<el-input
					v-model="about.address"
					auto-complete="off"
					placeholder="地址"
				/>
			</el-form-item>
			<el-form-item label="工作时间" prop="workTime">
				<el-input
					v-model="about.workTime"
					auto-complete="off"
					placeholder="工作时间"
				/>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" plain @click="handleSave">保存</el-button>
			</el-form-item>
		</el-form>
		
	</div>
</template>

<script>
export default {
	page: {
		title: '关于我们'
	},
	data() {
		return {
			about: {
				id: '',
				wechat: '',
				douyin: '',
				email: '',
				address: '',
				workTime: ''
			},
			formRules: {
				wechat: [{required:true, message: '请输入微信信息', trigger: 'blur'}],
				mpwechat: [{required:true, message: '请输入公众号信息', trigger: 'blur'}],
				douyin: [{required:true, message: '请输入抖音信息', trigger: 'blur'}],
				email: [{required:true, message: '请输入邮箱地址', trigger: 'blur'}],
				address: [{required:true, message: '请输入地址', trigger: 'blur'}],
				workTime: [{required:true, message: '请输入工作时间', trigger: 'blur'}],
			},
		}
	},
	methods: {
		handleSave() {
			this.$refs.about.validate((valid) => {
                if(valid) {
                    this.$api.contact.aboutUs.save(this.about)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleGetSingle() {
			this.$api.contact.aboutUs.getSingle()
			.then(response => {
				if(response.code === '0') {
					this.about = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		}
	},
	mounted() {
		this.handleGetSingle()
	}
}
</script>