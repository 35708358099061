<template>
	<div class="container">
		<div class="login-box">
			<div class="login-info">
				<div class="system-info">
					<img src="@/assets/images/logo.png" alt height="24" />
					<h3>内容管理系统</h3>
				</div>
				<div class="welcome">
					<h3>欢迎回来！</h3>
					<p>输入您的用户名和密码，以访问管理面板。</p>
				</div>
				<h4 class="account-label">用户名</h4>
				<el-input
					class="account-input"
					placeholder="请输入用户名"
					prefix-icon="el-icon-user"
					v-model="account"
				>
				</el-input>
				<h4 class="password-label">密码</h4>
				<el-input
					class="password-input"
					placeholder="请输入密码"
					prefix-icon="el-icon-lock"
					type="password"
					v-model="password"
				>
				</el-input>
				<el-checkbox class="rememberMe" v-model="rememberMe">记住我</el-checkbox>
				<el-button type="primary" class="submit" @click="handleLogin">登录</el-button>
			</div>
			<div class="auth-bg">
				<div class="mask">
					<p>Hope you happy everyday!</p>
				</div>
			</div>
		</div>
		<div class="copyright">版权所有：成都花开万物文化传播有限公司</div>
	</div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
import { getStorage } from "@/utils/storage"
const Base64 = require("js-base64").Base64
export default {
	page: {
		title: "用户登录"
	},
	data() {
		return {
			account: "",
			password: "",
			rememberMe: true
		}
	},
	computed: {
		...mapGetters({
			routes: 'routes'
		})
	},
	methods: {
		...mapActions(["auth/login"]),
		handleLogin() {
			this["auth/login"]({
				account: this.account,
				password: this.password,
				rememberMe: this.rememberMe
			})
			.then(response => {
				if(response.code === "0") {
					if(this.$route.query.redirect) {
						let url = decodeURIComponent(this.$route.query.redirect)
						this.$router.push({ path: url });
					}
					else {
						this.$router.push({path: '/'});
					}
				}
				else {
					this.$message({
						message: response.message
					});
				}
			})
		},
		handleResetLoginInfo() {
			if(getStorage("rememberMe")) {
				this.account = getStorage("account")
				this.password = Base64.decode(localStorage.getItem("password")); // base64解密
				this.rememberMe = true
			}
			else {
				this.account = ''
				this.password = ''
				this.rememberMe = false
			}
		}
	},
	mounted() {
		this.handleResetLoginInfo()
	}
}
</script>

<style scoped>
.container {
	background-color: #EDEFF4;
	height: 100%;
	padding-top: 60px;
	box-sizing: border-box;
}
.login-box {
	width: 936px;
	height: 510px;
	text-align: center;
	margin: 0 auto;
	box-sizing: border-box;
	background: #fff;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	vertical-align: middle;
}
.login-info {
	float: left;
	margin-left: 10px;
	width: 468px;
	height: 100%;
	padding: 30px;
}
.login-info .system-info {
	width: 100%;
	height: 24px;
	line-height: 24px;
}
.login-info .system-info h3 {
	float: left;
    letter-spacing: 1px;
	color: #4B4B5A;
	font-size: 20px;
}
.login-info .system-info img{
	float: left;
}
.login-info .welcome {
	margin-top: 40px;
	text-align: left;
}
.login-info .welcome h3{
	width: 100%;
	color: #4B4B5A;

}
.login-info .welcome p{
	color: #6C757D;
}
.auth-bg {
	float: right;
	margin-left: 10px;
	width: 448px;
	height: 100%;
	background-image: url(../../assets/images/auth-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}
.auth-bg .mask {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .4);
	position: relative;
}
.auth-bg .mask p {
	display: block;
	position: absolute;
	bottom: 50px;
	font-size: 24px;
	color: #fff;
	width: 100%;
}
.account-label {
	text-align: left;
	color: #4B4B5A;
	margin-top: 35px;
}
.account-input {
	margin-top: 10px;
}
.password-label {
	text-align: left;
	color: #4B4B5A;
	margin-top: 30px;
}
.password-input {
	margin-top: 10px;
}
.rememberMe {
	display: block;
	text-align: left;
	margin-top: 20px;
}
.submit {
	display: block;
	width: 100%;
	margin-top: 30px;
}
.copyright {
	color: #6C757D;
	font-size: 14px;
	margin: 0 auto;
	height: 20px;
	line-height: 20px;
	text-align: center;
	margin-top: 30px;
}
</style>