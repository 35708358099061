import request from "@/utils/request"

export default {
	add(pic) {
		return request({
			url: `/api/focus/focusPic/add`,
			method: "post",
			data: pic,
			options: {
                loading: {
					show: true,
					message: "正在保存焦点图信息，请稍候..."
				}
			}
		});
	},
	remove(picList) {
		return request({
			url: `/api/focus/focusPic/remove`,
			method: "post",
			data: picList,
			options: {
                loading: {
					show: true,
					message: "正在删除焦点图信息，请稍候..."
				}
			}
		});
	},
	edit(pic) {
		return request({
			url: `/api/focus/focusPic/edit`,
			method: "post",
			data: pic,
			options: {
                loading: {
					show: true,
					message: "正在保存焦点图信息，请稍候..."
				}
			}
		});
	},
	findAll(name, pageIndex, pageSize) {
		let queryString = `name=${name}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/focus/focusPic/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取焦点图列表，请稍候..."
				}
			}
		});	
	},
	transposition(picList) {
		return request({
			url: `/api/focus/focusPic/transposition`,
			method: "post",
			data: picList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	}
}