import request from "@/utils/request"

export default {
	add(photo) {
		return request({
			url: `/api/focus/photoWall/add`,
			method: "post",
			data: photo,
			options: {
                loading: {
					show: true,
					message: "正在保存图片信息，请稍候..."
				}
			}
		});
	},
	remove(photoList) {
		return request({
			url: `/api/focus/photoWall/remove`,
			method: "post",
			data: photoList,
			options: {
                loading: {
					show: true,
					message: "正在删除图片信息，请稍候..."
				}
			}
		});
	},
	edit(photo) {
		return request({
			url: `/api/focus/photoWall/edit`,
			method: "post",
			data: photo,
			options: {
                loading: {
					show: true,
					message: "正在保存图片信息，请稍候..."
				}
			}
		});
	},
	findAll(name, pageIndex, pageSize) {
		let queryString = `name=${name}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/focus/photoWall/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取图片列表，请稍候..."
				}
			}
		});	
	},
	transposition(photoList) {
		return request({
			url: `/api/focus/photoWall/transposition`,
			method: "post",
			data: photoList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	}
}