import { lazyLoadView } from "@/utils/lazyload"

let Layout = () => lazyLoadView(import('@/layout/index.vue'));

const protectedRoutes = [
	{
		path: '/setting',
		meta: {
			icon: 'setting',
			title: '站点管理'
		},
		component: Layout,
		children: [
			{
				path: 'administrator',
				component: () => lazyLoadView(import('@/views/auth/administrator/index.vue')),
				meta: {
					title: '管理员信息',
					authRequired: true
				}
			},
			{
				path: 'dic',
				component: () => lazyLoadView(import('@/views/setting/dic/index.vue')),
				meta: {
					title: '栏目管理',
					authRequired: true
				}
			},
			{
				path: 'pic',
				component: () => lazyLoadView(import('@/views/focus/pic/index')),
				meta: {
					title: '焦点图',
					authRequired: true
				}
			},
			{
				path: 'about',
				component: () => lazyLoadView(import('@/views/contact/about-us.vue')),
				meta: {
					title: '联系我们',
					authRequired: true
				}
			}
		]
	},
	{
		path: '/home',
		name: 'home',
		meta: {
			icon: 'home',
			title: '首页内容管理'
		},
		component: Layout,
		children: [
			{
				path: 'activity',
				name: 'activity',
				component: () => lazyLoadView(import('@/views/home/activity/index.vue')),
				meta: {
					title: '活动管理',
					authRequired: true
				}
			},
			{
				path: 'snowflake',
				name: 'snowflake',
				component: () => lazyLoadView(import('@/views/home/snowflake/index')),
				meta: {
					title: '雪花系',
					authRequired: true
				}
			}
		]
	},
	{
		path: '/focus',
		component: Layout,
		meta: {
			icon: 'bulb',
			title: '网站内容管理'
		},
		children: [
			{
				path: 'knowledge',
				component: () => lazyLoadView(import('@/views/knowledge/index')),
				meta: {
					title: '流行花艺解析',
					authRequired: true
				}
			},
			{
				path: 'photoWall',
				component: () => lazyLoadView(import('@/views/focus/photoWall/index')),
				meta: {
					title: '图库',
					authRequired: true
				}
			},
			{
				path: 'designer',
				component: () => lazyLoadView(import('@/views/commend/designer/index')),
				meta: {
					title: '设计师推荐',
					authRequired: true
				}
			},
			{
				path: 'industry',
				component: () => lazyLoadView(import('@/views/commend/industry/index')),
				meta: {
					title: '商家推荐',
					authRequired: true
				}
			},
			{
				path: 'industryRecruit',
				component: () => lazyLoadView(import('@/views/commend/IndustryRecruit/index')),
				meta: {
					title: '行业招聘',
					authRequired: true
				}
			},
		]
	},
	{
		path: '/order',
		meta: {
			icon: 'wallet',
			title: '会员/订单管理'
		},
		component: Layout,
		children: [
			{
				path: 'designer',
				component: () => lazyLoadView(import('@/views/order/prepay/index')),
				meta: {
					title: '订单管理',
					authRequired: true
				}
			},
			{
				path: 'user',
				component: () => lazyLoadView(import('@/views/order/user/index')),
				meta: {
					title: '会员管理',
					authRequired: true
				}
			}
		]
	},
	{
		path: '/document',
		meta: {
			icon: 'file-add-fill',
			title: 'VIP内容管理'
		},
		component: Layout,
		children: [
			{
				path: 'video',
				component: () => lazyLoadView(import('@/views/document/aliyun/video/index')),
				meta: {
					title: '视频管理',
					authRequired: true
				}
			},
			{
				path: 'audio',
				component: () => lazyLoadView(import('@/views/document/aliyun/audio/index')),
				meta: {
					title: '音频管理',
					authRequired: true
				}
			},
			{
				path: 'oss',
				component: () => lazyLoadView(import('@/views/document/aliyun/document/index')),
				meta: {
					title: '文档管理',
					authRequired: true
				}
			},
		]
	}
]

const baseRoutes = [];

const routes = baseRoutes.concat(protectedRoutes);

export default routes;