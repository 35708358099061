import request from "@/utils/request"

export default {
	add(designer) {
		return request({
			url: `/api/commend/designer/add`,
			method: "post",
			data: designer,
			options: {
                loading: {
					show: true,
					message: "正在保存设计师信息，请稍候..."
				}
			}
		});
	},
	edit(designer) {
		return request({
			url: `/api/commend/designer/edit`,
			method: "post",
			data: designer,
			options: {
                loading: {
					show: true,
					message: "正在保存设计师信息，请稍候..."
				}
			}
		});
	},
	remove(designerList) {
		return request({
			url: `/api/commend/designer/remove`,
			method: "post",
			data: designerList,
			options: {
                loading: {
					show: true,
					message: "正在批量删除设计师信息，请稍候..."
				}
			}
		});
	},
	findAll(chineseName, pageIndex, pageSize) {
		let queryString = `chineseName=${chineseName}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/commend/designer/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取设计师列表，请稍候..."
				}
			}
		});	
	},
	transposition(designerList) {
		return request({
			url: `/api/commend/designer/transposition`,
			method: "post",
			data: designerList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	}
}