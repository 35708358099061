import request from "@/utils/request"

export default {
	upload(file) {
		let fileData = new FormData();
		fileData.append('file', file.raw); //传文件

		return request({
			url: `/api/common/file/upload`,
			method: "post",
			data: fileData,
			options: {
                loading: {
					show: true,
					message: "正在上传文件，请稍候..."
				}
			}
		});
	}
}