<template>
    <div id="toolbar">
        <sidebar-activator />
        <el-breadcrumb separator-class="el-icon-arrow-right" class="current">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="spacer"></div>
        <el-dropdown class="avatar-container right-menu-item" trigger="click">
            <div class="avatar-wrapper">
                <img
                    class="user-avatar"
                    src="../../../assets/user.svg"
                />
                <i class="el-icon-caret-bottom"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
                <router-link to="/">
                    <el-dropdown-item>我的</el-dropdown-item>
                </router-link>
                <el-dropdown-item divided>
                    <span @click="logout" style="display: block">退出</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import SidebarActivator from "./sidebarActivator";
import { mapActions } from "vuex";
export default {
    name: "toolbar",
    components: { SidebarActivator },
    methods: {
		...mapActions(["auth/logout"]),
        logout() {
			this["auth/logout"]()
			.then(() => {
				location.reload();
			})
		},
    },
};
</script>

<style lang="scss" scoped>
@import "../../../style/variables";

#toolbar {
    height: var(--toolbar-height);
    display: inline-flex;
    flex-wrap: nowrap;
    border-bottom: solid 1px #ccc;
}
.current {
    line-height: var(--toolbar-height);
}
.avatar-container {
    height: 50px;
    margin-right: 30px;
    .avatar-wrapper {
        cursor: pointer;
        margin-top: 5px;
        position: relative;
        .user-avatar {
            width: 40px;
            height: 40px;
            border-radius: 10px;
        }
        .el-icon-caret-bottom {
            position: absolute;
            right: -20px;
            top: 25px;
            font-size: 12px;
        }
    }
}
</style>
