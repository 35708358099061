<template>
	<div id="layout" :style="STYLE_VARIABLES">
		<sidebar/>
		<outside/>
	</div>
</template>

<script>
	import Sidebar from "@/layout/sidebar/index";
	import Outside from "@/layout/outside/index";
	import {mapGetters} from 'vuex';

	export default {
		name: "layout",
		components: {Outside, Sidebar},
		computed: {
			...mapGetters({
				STYLE_VARIABLES: 'style/STYLE_VARIABLES',
			})
		},
	}
</script>

<style lang="scss" scoped>
	#layout {
		display: flex;
		height: 100vh;
		width: 100vw;
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-webkit-transform: translateZ(0);
		color: #2c3e50;
		overflow: hidden;
	}

</style>
