import video from "./video";
import audio from "./audio";
import document from "./document";
import knowledge from "./knowledge";

export default {
	video,
	audio,
	document,
	knowledge
}