<template>
	<div>
		<div class="toolbar">
            <el-button type="primary" plain @click="handleAdd">添加VIP音频</el-button>
            <el-button type="danger" plain @click="handleRemove">删除VIP音频</el-button>
            <el-input placeholder="按音频名称模糊查询" v-model="search.name">
                <el-button slot="append" icon="el-icon-search" @click="handleFindAll"></el-button>
            </el-input>
        </div>
		<el-table	
			:data="audioList"
			border
			stripe
			class="audioList"
			row-key="id"
			@selection-change="handleSelectionChange"
		>
			<el-table-column width="50px" label="序" align="center">
				<template>
					<i class="el-icon-rank" style="cursor: pointer; color: #E6A23C; font-size: 22px;" />
				</template>
			</el-table-column>
			<el-table-column type="selection" width="55px" align="center" />
			<el-table-column label="名称" prop="name" />
			<el-table-column label="分类" prop="category.value" align="center" width="150px" />
			<el-table-column label="创建时间" prop="createTime" width="170px" align="center" />
			<el-table-column label="排序" prop="sort" width="80px" align="center" />
			<el-table-column label="操作" width="120px" align="center">
				<template slot-scope="scope">
					<el-button type="primary" 
						icon="el-icon-edit" 
						circle
						@click="handleEdit(scope.row)">
					</el-button>
					<el-button type="danger" 
						icon="el-icon-delete" 
						circle 
						@click="handleRemoveSingle(scope.row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:current-page="paging.pageIndex"
			:page-sizes="[100, 200, 300, 400]"
			:page-size="paging.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="paging.recordCount" 
			class="paging"
			@current-change="handlePageIndexChanged"
			@size-change="handleSizeChange"
		/>
		<edit 
			:visible="showEditDialog" 
			:action="action" 
			ref="edit"
			@closeEditDialog="showEditDialog=false" 
			@refresh="handleRefresh"
		/>
	</div>
</template>

<script>
import sortable from 'sortablejs'
import edit from './components/edit.vue'
export default {
	page: 
	{
		title: '音频管理'
	},
	data() {
		return {
			showEditDialog: false,
			action: "create",
			audioList: [],
			sortedAudioList: [],
			search: {
				name: ''
			},
			paging: {
				pageIndex: 1,
				pageSize: 100,
				recordCount: 0,
				pageCount: 0
			},
			selected: []
		}
	},
	components: {
		edit
	},
	methods: {
		rowDrop() {
			const tbody = document.querySelector('.el-table__body-wrapper tbody')
			let that = this
			sortable.create(tbody, {
				onEnd({ newIndex, oldIndex }) {
					if(newIndex !== oldIndex) {

						if(that.audioList[oldIndex].category.id !== that.audioList[newIndex].category.id) {
							that.$message.info('拖动排序仅限在相同分类中进行操作')
							that.handleFindAll()
							return
						}

						that.sortedAudioList = []
						that.sortedAudioList.push(that.audioList[oldIndex])
						that.sortedAudioList.push(that.audioList[newIndex])

						that.$api.aliyun.audio.transposition(that.sortedAudioList)
						.then(response => {
							if(response.code === '0') {
								const currRow = that.audioList.splice(oldIndex, 1)[0]
								that.audioList.splice(newIndex, 0, currRow)
							}
							else {
								that.$message.error(response.message)
							}
						})
					}
				}
			})
		},
		handleEdit(audio) {
			this.showEditDialog = true
			this.action = "edit"
			this.$refs.edit.handleEdit(audio);
		},
		handleRemoveSingle(audio) {
			this.selected = []
			this.selected.push(audio)

			this.handleRemove()
		},
		handleSelectionChange(val) {
			this.selected = val
		},
		handlePageIndexChanged(currentPageIndex) {
			this.paging.pageIndex = currentPageIndex;
			this.handleFindAll();
		},
		handleSizeChange(currentPageSize) {
			this.paging.pageSize = currentPageSize;
			this.paging.pageIndex = 1;
			this.handleFindAll();
		},
		handleFindAll() {
			this.audioList = []
			this.$api.aliyun.audio.findAll(this.search.name, this.paging.pageIndex, this.paging.pageSize)
			.then(response => {
				if(response.code === "0") {
					this.audioList = response.data.audioList
					this.paging = response.data.paging
				}
				else
				{
					this.$message.error(response.message)
				}
			})
		},
		handleAdd() {
			this.action = "create"
			this.showEditDialog = true;
			this.$refs.edit.handleResetForm();
		},
		handleRemove() {
			if(this.selected.length == 0) {
				this.$message('请选择您要删除的项');
				return
			}

			this.$confirm('您确定要删除选中的项么?', '提示', 
			{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => 
			{
				this.$api.aliyun.audio.remove(this.selected)
				.then(response => {
					if(response.code === "0") {
						this.$message.success('已删除')
						this.handleFindAll()
					}
					else {
						this.$message.error(response.message)
					}
				})
			})
			.catch(() => {});
		},
		handleRefresh() {
			this.showEditDialog = false;
			this.handleFindAll();
		}
	},
	mounted() {
		this.handleFindAll()
		this.rowDrop()
	}
}
</script>

<style scoped>
.toolbar .el-input-group {
    margin-left: 10px;
    width: 300px;
}
.audioList {
	margin-top: 1rem;
}
.paging {
	margin: 1rem auto;
    text-align: center;
}
</style>