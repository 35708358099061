<template>
    <div>
        <el-dialog
            :title="action==='create'?'创建活动':'编辑活动'"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="1000px"
        >
            <el-form
                label-width="120px"
                :rules="formRules"
                :model="activity"
                ref="activity"
            >
                <el-form-item label="活动名称" prop="name">
                    <el-input
                        v-model="activity.name"
                        auto-complete="off"
                        placeholder="活动名称"
                    />
                </el-form-item>
                <el-form-item label="地点" prop="location">
                    <el-input
                        v-model="activity.location"
                        auto-complete="off"
                        placeholder="地点"
                    />
                </el-form-item>
                <el-form-item label="显示价格" prop="price">
					<el-input-number v-model="activity.price" :precision="2" :step="1" />
                </el-form-item>
				<el-form-item label="显示时间" prop="activityTime">
                    <el-input
                        v-model="activity.activityTime"
                        auto-complete="off"
                        placeholder="显示时间"
                    />
                </el-form-item>
                <el-form-item label="开始时间" prop="beginTime">
					<el-date-picker
						v-model="activity.beginTime"
						type="datetime"
						placeholder="开始时间"
						value-format="yyyy-MM-dd HH:mm"
					/>
                </el-form-item>
				<el-form-item label="结束时间" prop="endTime">
					<el-date-picker
						v-model="activity.endTime"
						type="datetime"
						placeholder="结束时间"
						value-format="yyyy-MM-dd HH:mm"
					/>
                </el-form-item>
                <el-form-item label="封面图" prop="cover">
                    <el-upload
                        ref="upload"
						class="avatar-uploader"
						action=""
						:on-change="handleUploadCover"
                        :show-file-list="false"
						:auto-upload="false"
                    >
                        <img v-if="activity.cover.absolutePath" :src="activity.cover.absolutePath" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    图片长宽比例 2:3
                </el-form-item>
                <el-form-item label="活动条件">
                    <el-checkbox-group v-model="activity.condition">
                        <el-checkbox v-for="condition in activityCondition" :label="condition.key" :key="condition.key">{{condition.value}}</el-checkbox>
              >
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="简介"
                        v-model="activity.wxts"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="介绍" prop="content">
                    <quill-editor
                        :options="editorOption"
                        v-model="activity.content"
                    >
                    </quill-editor>
                </el-form-item>
                <el-form-item label="活动地址">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="活动地址"
                        v-model="activity.xggz"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="活动费用">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="活动费用"
                        v-model="activity.smzgz"
                    >
                    </el-input>
                </el-form-item>
				<el-form-item label="退换规则">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="退换规则"
                        v-model="activity.thgz"
                    >
                    </el-input>
                </el-form-item>
				<el-form-item label="入场须知">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="入场须知"
                        v-model="activity.rcxz"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort" v-if="action !== 'create'">
                    <el-input-number
                        v-model="activity.sort"
                        :min="0"
						controls-position="right"
                    />
                </el-form-item>
				<el-form-item label="规格" prop="specList">
					<el-table
						:data="activity.specList"	
						border
						stripe
					>
						<el-table-column label="规格名称" align="center">
							<template slot-scope="scope">
								<el-input
									v-model="scope.row.name"
									auto-complete="off"
									placeholder="规格名称"
									size="small"
								/>
							</template>
						</el-table-column>
						<el-table-column label="库存" align="center">
							<template slot-scope="scope">
								<el-input-number
									v-model="scope.row.inventory"
									:min="0"
									size="small"
									controls-position="right"
								/>
							</template>
						</el-table-column>
						<el-table-column label="价格" align="center">
							<template slot-scope="scope">
								<el-input-number
									v-model="scope.row.price"
									:min="0.00"
									:precision="2"
									size="small"
									controls-position="right"
								/>
							</template>
						</el-table-column>
						<el-table-column label="排序" align="center">
							<template slot-scope="scope">
								<el-input-number
									v-model="scope.row.sort"
									:min="0"
									size="small"
									controls-position="right"
								/>
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center" width="100px">
							<template slot-scope="scope">
								<el-button 
									type="danger" 
									plain 
									size="small"
									@click="handleRemove(scope.$index)"
									>移除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" plain @click="handleAddSpec">新建规格</el-button>
				</el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button v-if="action==='create'" type="primary" plain @click="handleSave">保存</el-button>
                <el-button v-else type="success" plain @click="handleSaveUpdate">修改</el-button>
                <el-button plain @click="handleClose">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
/* begin 富文本编辑器 */
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import quill from "quill";
import { quillEditor } from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
quill.register("modules/imageDrop", ImageDrop);
quill.register("modules/imageResize", ImageResize);
/* end 富文本编辑器 */
export default {
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
		action: {
			type: String,
			required: true
		}
    },
    components: {
        quillEditor,
    },
    data() {
		var validateCover = (rule, cover, callback) => {
			if (cover.absolutePath === '') {
				callback(new Error('请上传封面图'));
			} 
			else {
				callback();
			}
		};
		var validateSpecList = (rule, specList, callback) => {
			if (specList.length === 0) {
				callback(new Error('请设置规格'));
			} 
			else {
				callback();
			}
		};
        return {
			activityCondition: [
				{ key: 'RealName', value: '实名制' },
				{ key: 'E-Ticket', value: '电子票' },
				{ key: 'NoRefund', value: '不支持退' },
				{ key: 'NoPutOff', value: '不支持延期' },
				{ key: 'WithIDCard', value: '携带身份证入场' },
				{ key: 'VIP', value: 'vip活动' },
				{ key: 'NAT48', value: '48小时核酸证明' },
			],
            editorOption: {
                debug: false,
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike", "image"],
                        ["formula", "clean"],
                        ["blockquote", "code-block"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ size: ["small", false, "large", "huge"] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        [{ direction: "rtl" }],
                    ],
                    history: {
                        delay: 1000,
                        maxStack: 50,
                        userOnly: false,
                    },
                    imageDrop: true,
                    imageResize: {
                        displayStyles: {
                            backgroundColor: "black",
                            border: "none",
                            color: "white",
                        },
                        modules: ["Resize", "DisplaySize", "Toolbar"],
                    },
                },
                placeholder: "输入内容........",
            },
            activity: {
				id: "",
                name: "",
                location: "",
                price: 0,
				activityTime: '',
                beginTime: "",
				endTime: "",
                cover: {
					absolutePath: "",
					relativePath: ""
				},
                condition: [],
                wxts: "",
                thgz: "",
                xggz: "",
                rcxz: "",
                smzgz: "",
                content: "",
                sort: 0,
				specList: []
            },
            formRules: {
                name: [{required: true, message: "请输入活动名称", trigger: "blur"}],
                location: [{required: true, message: "请输入地点", trigger: "blur"}],
                price: [{required: true, message: "请输入显示价格", trigger: "blur"}],
				activityTime: [{required: true, message: "请输入显示活动时间", trigger: "blur"}],
                beginTime: [{required: true, message: "请输入活动开始时间", trigger: "blur"}],
				endTime: [{required: true, message: "请输入活动结束时间", trigger: "blur"}],
				cover: [{required: true, validator: validateCover}],
                sort: [{required: true, message: "请输入排序", trigger: "blur"}],
                content: [{required: true, message: "请输入介绍内容", trigger: "blur"}],
				specList: [{required: true, validator: validateSpecList}]
            },
        };
    },
    methods: {
		handleUploadCover(file) {
			let fileExt = file.name.replace(/.+\./, "")

			if(['jpg', 'jpeg', 'png'].indexOf(fileExt.toLowerCase()) === -1) {
				this.$message.error('上传图片只能是JPG或PNG格式!');
				return;
			}
			
			this.$api.common.fileUpload.upload(file)
			.then(response => {
				if(response.code === "0") {
					this.activity.cover = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleResetForm() {
			this.activity = {
				id: "",
                name: "",
                location: "",
                price: "",
                activityTime: '',
                beginTime: "",
				endTime: "",
                cover: {
					absolutePath: "",
					relativePath: ""
				},
                condition: [],
                wxts: "",
                thgz: "",
                xggz: "",
                rcxz: "",
                smzgz: "",
                content: "",
                sort: 0,
				specList: []
            }
		},
        handleClose() {
			this.handleResetForm();
            this.$emit("closeEditDialog");
        },
		handleParentRefresh() {
			this.$emit("refresh");
		},
        handleSave() {
            this.$refs.activity.validate((valid) => {
                if(valid) {
                    this.$api.home.activity.add(this.activity)
					.then(response => {
						if(response.code === "0") {
							this.handleResetForm();
							this.handleParentRefresh();
							this.$message.success('已保存');
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
        },
		handleSaveUpdate() {
			this.$refs.activity.validate((valid) => {
                if(valid) {
                    this.$api.home.activity.edit(this.activity)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleEdit(activity) {
			this.activity = activity
		},
		handleAddSpec() {
			let spec = {
				id: '',
				name: '新规格名称',
				inventory: 0,
				price: 0.00,
				sort: 0
			}

			this.activity.specList.push(spec)
		},
		handleRemove(index) {
			this.$confirm('您确定要删除选中的规格么?', '提示', 
			{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => 
			{
				let spec = this.activity.specList[index]

				if(spec.id !== "" && typeof spec.id !== "undefined") {
					this.$api.home.activity.removeSpec(spec)
					.then(response => {
						if(response.code === "0") {
							this.activity.specList.splice(index, 1)
						}
						else {
							this.$message.error(response.message)
						}
					})
				}
				else {
					this.activity.specList.splice(index, 1)
				}
			})
			.catch(() => {});
		}
    },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
}
.avatar-uploader-icon:hover {
    border-color: #409eff;
}
.avatar {
    width: 178px;
    display: block;
    border: 1px dashed #d9d9d9;
}
.avatar:hover {
    border-color: #409eff;
}
::v-deep .ql-container {
    height: 66vh;
}
</style>