import request from "@/utils/request"

export default {
	add(dic) {
		return request({
			url: `/api/setting/dictionary/add`,
			method: "post",
			data: dic,
			options: {
                loading: {
					show: true,
					message: "正在保存字典信息，请稍候..."
				}
			}
		});
	},
	remove(dicList) {
		return request({
			url: `/api/setting/dictionary/remove`,
			method: "post",
			data: dicList,
			options: {
                loading: {
					show: true,
					message: "正在删除字典信息，请稍候..."
				}
			}
		});
	},
	edit(dic) {
		return request({
			url: `/api/setting/dictionary/edit`,
			method: "post",
			data: dic,
			options: {
                loading: {
					show: true,
					message: "正在保存字典信息，请稍候..."
				}
			}
		});
	},
	findAll(value, category) {
		return request({
			url: `/api/setting/dictionary/findAll?value=${value}&category=${category}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取字典信息，请稍候..."
				}
			}
		});
	},
	transposition(dicList) {
		return request({
			url: `/api/setting/dictionary/transposition`,
			method: "post",
			data: dicList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	}
}