import request from "@/utils/request"

export default {
	upload(file) {
		return request({
			url: `/api/aliyun/oss/upload`,
			method: "post",
			data: file,
			options: {
                loading: {
					show: false
				}
			}
		});
	},
	getAliyunOssAcountInfo() {
		return request({
			url: `/api/aliyun/oss/getAliyunOssAcountInfo`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在阿里云存储账号信息，请稍候..."
				}
			}
		});	
	}
}