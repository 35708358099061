<template>
	<div>
		<el-dialog
            :title="action==='create'?'添加管理员':'编辑管理员'"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="1000px"
        >
			<el-form
                label-width="120px"
                :rules="formRules"
                :model="administrator"
                ref="administrator"
            >
                <el-form-item label="账号" prop="account">
                    <el-input
                        v-model="administrator.account"
                        auto-complete="off"
                        placeholder="账号"
                    />
                </el-form-item>
				<el-form-item label="密码" :prop="action==='create'?'password':''">
                    <el-input
                        v-model="administrator.password"
                        auto-complete="off"
                        placeholder="密码"
                    />
                </el-form-item>
				<el-form-item label="昵称" prop="nickname">
                    <el-input
                        v-model="administrator.nickname"
                        auto-complete="off"
                        placeholder="昵称"
                    />
                </el-form-item>
				<el-form-item label="状态" prop="enabled">
					<el-radio v-model="administrator.enabled" :label="true">在用</el-radio>
  					<el-radio v-model="administrator.enabled" :label="false">停用</el-radio>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
                <el-button v-if="action==='create'" type="primary" plain @click="handleSave">保存</el-button>
                <el-button v-else type="success" plain @click="handleSaveUpdate">修改</el-button>
                <el-button plain @click="handleClose">取消</el-button>
            </div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		visible: {
            type: Boolean,
            required: true,
        },
		action: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			administrator: {
				id: '',
				account: '',
				password: '',
				nickname: '',
				enabled: true
			},
			formRules: {
				account: [{required: true, message: "请输入账号", trigger: "blur"}],
				password: [{required: true, message: "请输入密码", trigger: "blur"}],
				nickname: [{required: true, message: "请输入昵称", trigger: "blur"}],
			}
		}
	},
	methods: {
		handleClose() {
			this.$emit("closeEditDialog");
		},
		handleResetForm() {
			this.administrator = {
				id: '',
				account: '',
				password: '',
				nickname: '',
				enabled: true
			}
		},
		handleEdit(administrator) {
			this.administrator = administrator
		},
		handleParentRefresh() {
			this.$emit("refresh");
		},
		handleSave() {
			this.$refs.administrator.validate((valid) => {
                if(valid) {
                    this.$api.auth.administrator.add(this.administrator)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleSaveUpdate() {
			this.$refs.administrator.validate((valid) => {
                if(valid) {
                    this.$api.auth.administrator.edit(this.administrator)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		}
	},
	mounted() {
		
	}
}
</script>
