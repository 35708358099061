<template>
	<div>
		<el-dialog
            title="设置会员视频权限"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="500px"
        >
			<el-table
				ref="multipleTable"
				:data="category"
				tooltip-effect="dark"
				style="width: 100%"
				border
				stripe
				@selection-change="handleSelectionChange"
			>
				<el-table-column
					type="selection"
					align="center"
					width="55">
				</el-table-column>
				<el-table-column
					prop="value"
					label="视频分类">
				</el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
                <el-button type="primary" plain @click="handleSave">保存</el-button>
                <el-button plain @click="handleClose">取消</el-button>
            </div>
		</el-dialog>

	</div>
</template>

<script>
export default {
	props: {
        visible: {
            type: Boolean,
            required: true,
        },
    },
	data() {
		return {
			user: {
				userVideoRights: []
			},
			category: [],
			multipleSelection: []
		}
	},
	methods: {
		handleClose() {
            this.$emit("closeDialog");
        },
		getUserVideoRights(user) {

			this.user = user

			this.multipleSelection = []

			this.$api.auth.user.getUserVideoRights(user.id)
			.then(response => {
				if(response.code === '0') {

					let userVideoRights = response.data

					this.category.forEach(category => {
						userVideoRights.forEach(right => {
							if(category.id === right.dictionary.id) {
								this.multipleSelection.push(category)
								this.$refs.multipleTable.toggleRowSelection(category, true);
							}
						})
					})
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		findAllVideoDictionary() {
			this.$api.setting.dic.findAll('', 'VIP视频')
			.then(response => {
				if(response.code === '0') {
					this.category = response.data
				}
				else {
					this.$message.info(response.message)
				}
			})
		},
		handleSave() {
			let userVideoRights = []

			this.multipleSelection.forEach(rights => {
				userVideoRights.push(
					{
						dictionary: rights
					}
				)
			})

			this.user.userVideoRights = userVideoRights

			this.$api.auth.user.changeUserVideoRights(this.user)
			.then(response => {
				if(response.code === '0') {
					this.handleParentRefresh();
					this.$message.success('已保存')
				}
				else
				{
					this.$message.info(response.message)
				}
			})
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		handleParentRefresh() {
			this.$emit("refresh");
		},
	},
	mounted() {
		this.findAllVideoDictionary()
	}
}
</script>