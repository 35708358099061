<template>
	<div>
		<div>
			<el-form :inline="true" :model="search">
				<el-form-item label="中文名称">
					<el-input v-model="search.value" placeholder="中文名称" clearable></el-input>
				</el-form-item>
				<el-form-item label="栏目">
					<el-input v-model="search.category" placeholder="栏目" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="success" plain @click="handleFindAll">查询</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" plain @click="handleAdd()">添加栏目</el-button>
				</el-form-item>
				<el-form-item>
					<el-button type="danger" plain @click="handleRemove">删除栏目</el-button>
				</el-form-item>
			</el-form>
        </div>
		<el-table	
			:data="dicList"
			border
			stripe
			class="dicList"
			row-key="id"
			:tree-props="{children: 'child'}"
			@selection-change="handleSelectionChange"
		>
			<el-table-column width="50px" label="序" align="center">
				<template>
					<i class="el-icon-rank" style="cursor: pointer; color: #E6A23C; font-size: 22px;" />
				</template>
			</el-table-column>
			<el-table-column type="selection" width="55px" align="center" />
			<el-table-column label="英文名称" prop="code" align="center" width="150px" />
			<el-table-column label="中文名称" prop="value" />
			<el-table-column label="栏目" prop="category" align="center" width="180px" />
			<el-table-column label="创建时间" prop="createTime" align="center" width="170px" />
			<el-table-column label="排序" prop="sort" width="80px" align="center" />
			<el-table-column label="操作" width="120px" align="center">
				<template slot-scope="scope">
					<!-- 不允许添加子类 -->
					<!-- <el-button type="success" 
						icon="el-icon-bottom-right" 
						circle
						v-if="typeof scope.row.parent === 'undefined'"
						@click="handleAdd(scope.row)">
					</el-button> -->
					<el-button type="primary" 
						icon="el-icon-edit" 
						circle
						@click="handleEdit(scope.row)">
					</el-button>
					<el-button type="danger" 
						icon="el-icon-delete" 
						circle 
						@click="handleRemoveSingle(scope.row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<edit 
			:action="action" 
			:visible="showEditDialog" 
			:parent="parent"
			@closeEditDialog="showEditDialog=false"
			@refresh="handleRefresh"
			ref="edit" />
	</div>
</template>

<script>
import sortable from 'sortablejs'
import edit from './components/edit.vue'
export default {
	page: {
		title: '栏目管理'
	},
	components: {
		edit
	},
	data() {
		return {
			search: {
				value: '',
				category: ''
			},
			parent: {},
			showEditDialog: false,
			action: "create",
			dicList: [],
			sortedDicList: [],
			selected: [],
			paging: {
				pageIndex: 1,
				pageSize: 100,
				recordCount: 0,
				pageCount: 0
			},
			formRules: {

			}
		}
	},
	methods: {
		rowDrop() {
			const tbody = document.querySelector('.el-table__body-wrapper tbody')
			let that = this
			sortable.create(tbody, {
				onEnd({ newIndex, oldIndex }) {
					if(newIndex !== oldIndex) {

						if(that.dicList[oldIndex].category !== that.dicList[newIndex].category) {
							that.$message.info('拖动排序仅限在相同分类中进行操作')
							that.handleFindAll()
							return
						}

						that.sortedDicList = []
						that.sortedDicList.push(that.dicList[oldIndex])
						that.sortedDicList.push(that.dicList[newIndex])

						// 接口要求第一个对象必须是原始对象，第二个对象才是目标对象
						that.$api.setting.dic.transposition(that.sortedDicList)
						.then(response => {
							if(response.code === '0') {
								that.handleFindAll()
							}
							else {
								that.$message.error(response.message)
							}
						})
					}
				}
			})
		},
		handleAdd(parent) {
			this.action = 'create';
			this.showEditDialog = true;
			this.parent = parent;
			this.$refs.edit.handleResetForm();
		},
		handleEdit(dic) {
			this.showEditDialog = true
			this.action = "edit"
			this.parent = dic
			this.$refs.edit.handleEdit(dic);
		},
		handleRemove() {
			if(this.selected.length == 0) {
				this.$message('请选择您要删除的栏目');
				return
			}

			this.$confirm('您确定要删除选中的栏目么?', '提示', 
			{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => 
			{
				this.$api.setting.dic.remove(this.selected)
				.then(response => {
					if(response.code === "0") {
						this.$message.success('已删除')
						this.handleFindAll()
					}
					else {
						this.$message.error(response.message)
					}
				})
			})
			.catch(() => {});
		},
		handleRemoveSingle(dic) {
			this.selected = []
			this.selected.push(dic)

			this.handleRemove()
		},
		handleFindAll() {
			this.dicList = []
			this.$api.setting.dic.findAll(this.search.value, this.search.category)
			.then(response => {
				if(response.code === "0") {
					this.dicList = response.data;
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleRefresh() {
			this.showEditDialog = false;
			this.handleFindAll();
		},
		handleSelectionChange(val) {
			this.selected = val;
		}
	},
	mounted() {
		this.handleFindAll();
		this.rowDrop()
	}
}
</script>
