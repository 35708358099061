<template>
	<div id="app">
		花开万物 - 内容发布系统
	</div>
</template>

<script>
export default {
	page: {
		title: "花开万物 - 内容发布系统"
	},
	data() {
		return {

		}
	}
}
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-webkit-transform: translateZ(0);
		color: #2c3e50;
		overflow: hidden;
	}

	body {
		white-space: nowrap;
		margin: 0;
		padding: 0;
	}
</style>
