import request from "@/utils/request"

export default {
	add(audio) {
		return request({
			url: `/api/aliyun/audio/add`,
			method: "post",
			data: audio,
			options: {
                loading: {
					show: true,
					message: "正在保存音频信息，请稍候..."
				}
			}
		});
	},
	findAll(name, pageIndex, pageSize) {
		let queryString = `name=${name}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/aliyun/audio/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取音频列表，请稍候..."
				}
			}
		});	
	},
	remove(audioList) {
		return request({
			url: `/api/aliyun/audio/remove`,
			method: "post",
			data: audioList,
			options: {
                loading: {
					show: true,
					message: "正在批量删除音频信息，请稍候..."
				}
			}
		});
	},
	edit(audio) {
		return request({
			url: `/api/aliyun/audio/edit`,
			method: "post",
			data: audio,
			options: {
                loading: {
					show: true,
					message: "正在保存音频信息，请稍候..."
				}
			}
		});
	},
	transposition(audioList) {
		return request({
			url: `/api/aliyun/audio/transposition`,
			method: "post",
			data: audioList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	}
}