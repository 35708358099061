import auth from "./auth"
import home from "./home"
import common from "./common"
import focus from "./focus"
import setting from "./setting"
import commend from "./commend"
import contact from "./contact"
import aliyun from "./aliyun"

export default {
	auth,
	home,
	common,
	focus,
	setting,
	commend,
	contact,
	aliyun
}