import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './state'
import VueRouter from "vue-router"
import './assets/ant-icon'
import './plugins/element-ui'
import './plugins/custom-ui'

import './style/index.scss'


import api from "@/api/index"
Vue.prototype.$api = api

Vue.use(VueRouter)

Vue.config.productionTip = false

Vue.prototype.$vars = store.state.style.vars;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
