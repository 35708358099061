<template>
    <div id="app">
      	<router-view />
    </div>
</template>

<script>
export default {
    page: {
        // 所有的子组件标题都将被注入到这个模板中。
        titleTemplate(title) {
            title = typeof title === "function" ? title(this.$store) : title;
            return title;
        },
    },
};
</script>

<!-- 应用程序唯一全局css -->
<style>
html {
    width: 100%;
    height: 100%;
}
body {
    width: 100%;
    height: 100%;
}
#app {
    width: 100%;
    height: 100%;
}
</style>
