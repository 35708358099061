<template>
	<div>
		<div class="toolbar">
            <el-button type="primary" plain @click="handleAdd">添加管理员</el-button>
            <el-button type="danger" plain @click="handleRemove">删除管理员</el-button>
			<el-input placeholder="按管理员账号模糊查询" v-model="search.account">
                <el-button slot="append" icon="el-icon-search" @click="handleFindAll"></el-button>
            </el-input>
        </div>
		<el-table	
			:data="administratorList"
			border
			stripe
			class="administratorList"
			@selection-change="handleSelectionChange"
		>
			<el-table-column type="selection" width="55px" align="center" />
			<el-table-column label="账号" prop="account" />
			<el-table-column label="昵称" prop="nickname" align="center" width="150px" />
			<el-table-column label="状态" align="center" width="50px">
				<template slot-scope="scope">
					<i 
						v-if="!scope.row.enabled" 
						class="el-icon-lock" 
						style="color: red;font-size: 22px;cursor: pointer;" 
						title="点击解锁当前管理员账号"
						@click="handleChangeEnabled(scope.row)"
					/>
					<i 
						v-else 
						class="el-icon-unlock" 
						style="color: green;font-size: 22px;cursor: pointer;" 
						title="点击锁定当前管理员账号"
						@click="handleChangeEnabled(scope.row)"
					/>
				</template>
			</el-table-column>		
			<el-table-column label="创建时间" prop="createTime" align="center" width="170px" />
			<el-table-column label="操作" width="150px" align="center">
				<template slot-scope="scope">
					<el-button type="primary" 
						icon="el-icon-edit" 
						circle
						@click="handleEdit(scope.row)">
					</el-button>
					<el-button type="danger" 
						icon="el-icon-delete" 
						circle 
						@click="handleRemoveSingle(scope.row)">
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:current-page="paging.pageIndex"
			:page-sizes="[100, 200, 300, 400]"
			:page-size="paging.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="paging.recordCount" 
			class="paging"
			@current-change="handlePageIndexChanged"
			@size-change="handleSizeChange"
		/>
		<edit 
			:action="action" 
			:visible="showEditDialog" 
			@closeEditDialog="showEditDialog=false"
			@refresh="handleRefresh"
			ref="edit" />
	</div>
</template>

<script>
import edit from './components/edit.vue';
export default {
	page: {
		title: '管理员列表'
	},
	components: {
		edit
	},
	data() {
		return {
			search: {
				account: ''
			},
			showEditDialog: false,
			action: "create",
			administratorList: [],
			selected: [],
			paging: {
				pageIndex: 1,
				pageSize: 100,
				recordCount: 0,
				pageCount: 0
			},
		}
	},
	methods: {
		handleAdd() {
			this.action = 'create';
			this.showEditDialog = true;
		},
		handleEdit(administrator) {
			this.showEditDialog = true
			this.action = "edit"
			this.$refs.edit.handleEdit(administrator);
		},
		handleRemove() {
			if(this.selected.length == 0) {
				this.$message('请选择您要删除的管理员');
				return
			}

			this.$confirm('您确定要删除选中的管理员么?', '提示', 
			{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => 
			{
				this.$api.auth.administrator.remove(this.selected)
				.then(response => {
					if(response.code === "0") {
						this.$message.success('已删除')
						this.handleFindAll()
					}
					else {
						this.$message.error(response.message)
					}
				})
			})
			.catch(() => {});
		},
		handleRemoveSingle(administrator) {
			this.selected = []
			this.selected.push(administrator)

			this.handleRemove()
		},
		handleFindAll() {
			this.$api.auth.administrator.findAll(this.search.account, this.paging.pageIndex, this.paging.pageSize)
			.then(response => {
				if(response.code === "0") {
					this.administratorList = response.data.administratorList
					this.paging = response.data.paging
				}
				else {
					this.$message.error(response.message);
				}
			})
		},
		handleRefresh() {
			this.showEditDialog = false;
			this.handleFindAll();
		},
		handleSelectionChange(val) {
			this.selected = val
		},
		handlePageIndexChanged(currentPageIndex) {
			this.paging.pageIndex = currentPageIndex;
			this.handleFindAll();
		},
		handleSizeChange(currentPageSize) {
			this.paging.pageSize = currentPageSize;
			this.paging.pageIndex = 1;
			this.handleFindAll();
		},
		handleChangeEnabled(administrator) {
			this.$api.auth.administrator.changeEnabled(administrator.id, !administrator.enabled)
			.then(response => {
				if(response.code === "0") {
					administrator.enabled = !administrator.enabled;
				}
				else {
					this.$message.error(response.message);
				}
			})
		}
	},
	mounted() {
		this.handleFindAll()
	}
}
</script>

<style scoped>
.toolbar .el-input-group {
    margin-left: 10px;
    width: 300px;
}
.administratorList {
	margin-top: 1rem;
}
.paging {
	margin: 1rem auto;
    text-align: center;
}
.thumbnail {
	width: 120px;
}
</style>