<template>
	<div>
		<div class="toolbar">
			<el-input placeholder="按会员手机号模糊查询" v-model="search.phoneNumbers">
                <el-button slot="append" icon="el-icon-search" @click="handleFindAll"></el-button>
            </el-input>
			<el-button type="primary" plain @click="handleShowHelpRegisterDialog">代注册</el-button>
		</div>
		<el-table	
			:data="userList"
			border
			stripe
			class="userList"
			row-key="id"
		>
			<el-table-column align="center" width="90"  label="序号">
				<template slot-scope="scope">
					<span>{{ scope.$index + ( paging.pageIndex - 1 ) * paging.pageSize + 1 }}</span>   
				</template>
			</el-table-column>
			<el-table-column label="联系电话" prop="phoneNumbers" width="120px" align="center" />
			<el-table-column label="账户级别" width="80px" align="center">
				<template slot-scope="scope">
					<el-tag type="success" effect="plain" v-if="scope.row.grade === 'normal'">普通</el-tag>
					<el-popover
						placement="bottom"
						width="400"
						trigger="click"
						v-else
					>
						<el-form ref="form" label-width="80px">
							<el-form-item label="VIP权限">
								<el-checkbox-group v-model="vipRoles">
									<el-checkbox label="视频"></el-checkbox>
									<el-checkbox label="音频"></el-checkbox>
									<el-checkbox label="文档下载"></el-checkbox>
								</el-checkbox-group>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="handleEditVipRoles(scope.row)">保存</el-button>
							</el-form-item>
						</el-form>
						<el-tag slot="reference" type="danger" effect="dark" @click="handleShowVipRoles(scope.row)">VIP</el-tag>
					</el-popover>
				</template>
			</el-table-column>
			<el-table-column label="地址" prop="address" />
			<el-table-column label="消费记录" width="120px" align="center">
				<template slot-scope="scope">
					<el-button type="primary" size="small" @click="handleShowUserPrepayRecordDialog(scope.row)">消费记录</el-button>
				</template>
			</el-table-column>
			<el-table-column label="管理员备注" prop="adminRemark" align="center" />
			<el-table-column label="账号操作" width="150px" align="center">
				<template slot-scope="scope">
					<el-popover
						placement="bottom"
						width="400"
						trigger="click"
						v-if="scope.row.grade === 'normal'"
					>
						<el-form ref="form" label-width="80px">
							<el-form-item label="VIP权限">
								<el-checkbox-group v-model="vipRoles">
									<el-checkbox label="视频"></el-checkbox>
									<el-checkbox label="音频"></el-checkbox>
									<el-checkbox label="文档下载"></el-checkbox>
								</el-checkbox-group>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="handleGradeUp(scope.row)">保存</el-button>
							</el-form-item>
						</el-form>
						<el-button slot="reference" size="small" type="success">升级</el-button>
					</el-popover>
					<el-button v-else type="info" size="small" @click="handleGradeDown(scope.row)">降级</el-button>
					<i 
						v-if="!scope.row.blackList" 
						class="el-icon-unlock" 
						style="color: green;font-size: 22px;cursor: pointer; margin-left: 10px;" 
						title="点击锁定当前会员账号"
						@click="handleChangeEnabled(scope.row)"
					/>
					<i 
						v-else
						class="el-icon-lock" 
						style="color: red;font-size: 22px;cursor: pointer; margin-left: 10px;" 
						title="点击解锁当前会员账号"
						@click="handleChangeEnabled(scope.row)"
					/>
				</template>
			</el-table-column>
			<el-table-column label="注册时间" prop="createTime" width="170px" align="center" />
			<el-table-column label="操作" width="180px" align="center">
				<template slot-scope="scope">
					<el-popover
						placement="left"
						width="400"
						trigger="click"
					>
						<el-form ref="form" label-width="80px">
							<el-form-item label="备注">
								<el-input type="textarea" :rows="4" v-model="adminRemark"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="handleSaveAdminRemark">保存</el-button>
							</el-form-item>
						</el-form>
						<el-button slot="reference" circle icon="el-icon-edit" @click="handleShowAdminRemark(scope.row)"></el-button>
					</el-popover>
					<el-button type="danger" 
						icon="el-icon-delete" 
						circle 
						style="margin-left: 10px"
						@click="handleRemove(scope.row)"
					>
					</el-button>
					<el-button type="success" 
						icon="el-icon-video-camera" 
						circle 
						style="margin-left: 10px"
						@click="handleShowVideoRightsDialog(scope.row)"
					>
					</el-button>
				</template>
			</el-table-column>
		</el-table>	
		<el-pagination
			:current-page="paging.pageIndex"
			:page-sizes="[100, 200, 300, 400]"
			:page-size="paging.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="paging.recordCount" 
			class="paging"
			@current-change="handlePageIndexChanged"
			@size-change="handleSizeChange"
		/>
		<prepay-record 
			:visible="showUserPrepayRecordDialog"
			ref="payRecord"
			@closeDialog="showUserPrepayRecordDialog=false" 
		/>
		<help-register
			:visible="showHelpRegisterDialog"
			ref="helpRegister"
			@closeDialog="showHelpRegisterDialog=false" 
			@refresh="handleRefresh"
		/>
		<video-rights
			:visible="showUserVideoRights"
			:user="selectedUser.id"
			ref="videoRights"
			@closeDialog="showUserVideoRights=false" 
			@refresh="handleRefresh" />
	</div>
</template>

<script>
import PrepayRecord from "./components/PrepayRecord.vue"
import HelpRegister from "./components/HelpRegister.vue"
import VideoRights from "./components/VideoRights.vue"
export default {
	page: {
		title: '会员管理'
	},
	data() {
		return {
			search: {
				phoneNumbers: ''
			},
			paging: {
				pageIndex: 1,
				pageSize: 100,
				recordCount: 0,
				pageCount: 0
			},
			userList: [],
			showUserPrepayRecordDialog: false,
			showHelpRegisterDialog: false,
			showUserVideoRights: false,
			selectedUser: {
				id: ''
			},
			adminRemark: '',
			vipRoles: []
		}
	},
	components: {
		PrepayRecord,
		HelpRegister,
		VideoRights
	},
	methods: {
		handleShowVipRoles(user) {
			this.selectedUser = user
			this.vipRoles = user.vipRoles.split(',')
		},
		handleEditVipRoles(user) {
			this.handleGradeUp(user)
		},
		handleFindAll() {
			this.$api.auth.user.findAll
			(
				this.search.phoneNumbers, 
				this.paging.pageIndex, 
				this.paging.pageSize
			)
			.then(response => {
				if(response.code === '0') {
					this.userList = response.data.userList
					this.paging = response.data.paging
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handlePageIndexChanged(currentPageIndex) {
			this.paging.pageIndex = currentPageIndex;
			this.handleFindAll();
		},
		handleSizeChange(currentPageSize) {
			this.paging.pageSize = currentPageSize;
			this.paging.pageIndex = 1;
			this.handleFindAll();
		},
		handleGradeDown(user) {
			let grade = 'normal'
			this.vipRoles = []

			this.$api.auth.user.gradeUpOrDown({
				id: user.id,
				grade: grade,
				vipRoles: ''
			})
			.then(response => {
				if(response.code === '0') {
					user.grade = grade
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleGradeUp(user) {
			let grade = 'VIP'	

			if(this.vipRoles.length === 0) {
				this.$message.info('升级为VIP会员，请至少选择一项VIP权限')
				return
			}

			this.$api.auth.user.gradeUpOrDown({
				id: user.id,
				grade: grade,
				vipRoles: this.vipRoles.join(',')
			})
			.then(response => {
				if(response.code === '0') {
					user.grade = grade
					user.vipRoles = this.vipRoles.join(',')
					this.vipRoles = []
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleShowUserPrepayRecordDialog(user) {
			this.showUserPrepayRecordDialog = true
			this.$refs.payRecord.handleFindUserPrepayRecord(user)
		},
		handleShowHelpRegisterDialog() {
			this.showHelpRegisterDialog = true
		},
		handleShowVideoRightsDialog(user) {
			this.$refs.videoRights.getUserVideoRights(user)
			this.showUserVideoRights = true
		},
		handleShowAdminRemark(user) {
			this.selectedUser = user
		},
		handleSaveAdminRemark() {
			if(this.adminRemark === '') {
				this.$message.info('备注不能为空')
				return
			}

			this.$api.auth.user.editAdminRemark({ id: this.selectedUser.id, adminRemark: this.adminRemark })
			.then(response => {
				if(response.code === '0') {
					this.selectedUser.adminRemark = this.adminRemark
					this.adminRemark = ''
					this.$message.success('已保存')
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleRemove(user) {
			this.$confirm('您确定要删除选中的会员信息么?', '提示', 
			{
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
			.then(() => 
			{
				this.$api.auth.user.removeUser(user)
				.then(response => {
					if(response.code === '0') {
						this.handleFindAll()
						this.$message.success('已删除')
					}
					else {
						this.$message.error(response.message)
					}
				})
			})
			.catch(() => {});
		},
		handleRefresh() {
			this.showHelpRegisterDialog = false;
			this.showUserVideoRights = false
			this.handleFindAll();
		},
		handleChangeEnabled(user) {
			this.$api.auth.user.changeBlackList(user.id, !user.blackList)
			.then(response => {
				if(response.code === "0") {
					user.blackList = !user.blackList;
				}
				else {
					this.$message.error(response.message);
				}
			})
		}
	},
	mounted() {
		this.handleFindAll()
	}
}
</script>

<style scoped>
.toolbar .el-input-group {
	margin-right: 10px;
    width: 300px;
}
.userList {
	margin-top: 1rem;
}
.paging {
	margin: 1rem auto;
    text-align: center;
}
</style>