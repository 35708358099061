import { lazyLoadView } from "@/utils/lazyload"

let Layout = () => lazyLoadView(import('@/layout/index.vue'));

const frameworkRoutes = [
	{
		path: '/login',
		name: 'login',
		component: () => lazyLoadView(import('@/views/auth/login')),
	},
	{
		path: '*',
		component: () => lazyLoadView(import('@/views/auth/login')),
	},
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '',
				component: () => lazyLoadView(import('@/views/dashboard/index')),
				meta: {
					icon: '',
					title: '花开万物 - 内容发布系统',
					authRequired: true
				}
			}
		]
	}
]

const baseRoutes = [];

const routes = baseRoutes.concat(frameworkRoutes);

export default routes;