const OSS = require('ali-oss')

export function client(data) {
	return new OSS({
		region: 'oss-cn-shanghai', 
		accessKeyId: data.accessKeyId,
		accessKeySecret: data.accessKeySecret,
		bucket: data.bucketName,
		endpoint: data.endpoint
	})
}