import request from "@/utils/request"

export default {
	upload(file, onUploadProgress) {
		return request({
			url: `/api/aliyun/vod/upload`,
			method: "post",
			data: file,
			onUploadProgress,
			options: {
                loading: {
					show: true,
					message: "正在上传文件，请稍候..."
				}
			}
		});
	},
	getUploadAuthAndAddress(title, fileName, regionId) {
		let queryString = `title=${title}&fileName=${fileName}&regionId=${regionId}`

		return request({
			url: `/api/aliyun/vod/getUploadAuthAndAddress?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取阿里云点播上传授权信息，请稍候..."
				}
			}
		});
	},
	refreshUploadAuthAndAddress(videoId, regionId) {
		let queryString = `videoId=${videoId}&regionId=${regionId}`

		return request({
			url: `/api/aliyun/vod/refreshUploadAuthAndAddress?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在刷新阿里云点播上传授权信息，请稍候..."
				}
			}
		});
	},
	getPlayInfo(videoId, regionId) {	
		let queryString = `videoId=${videoId}&regionId=${regionId}`

		return request({
			url: `/api/aliyun/vod/getPlayInfo?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取阿里云点播信息，请稍候..."
				}
			}
		});
	}
}