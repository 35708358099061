<template>
	<div>
		<el-dialog
            :title="action==='create'?'添加栏目':'编辑栏目'"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="1000px"
        >
			<el-form
                label-width="120px"
                :rules="formRules"
                :model="dic"
                ref="dic"
            >
				<el-form-item label="父级" v-if="action==='create'">
					<template>
						{{typeof parent !== 'undefined' ? `英文名称=>${parent.code}，中文名称=>${parent.value}` : '无'}}
					</template>
				</el-form-item>
				<el-form-item label="英文名称" prop="code">
                    <el-input
                        v-model="dic.code"
                        auto-complete="off"
                        placeholder="英文名称"
                    />
                </el-form-item>
				<el-form-item label="中文名称" prop="value">
                    <el-input
                        v-model="dic.value"
                        auto-complete="off"
                        placeholder="中文名称"
                    />
                </el-form-item>
				<el-form-item label="栏目" prop="category">
					<el-select v-model="dic.category" placeholder="请选择">
						<el-option
							v-for="item in categoryList"
							:key="item"
							:label="item"
							:value="item"
						>
						</el-option>
					</el-select>
                </el-form-item>
				<el-form-item label="排序" prop="sort" v-if="action !== 'create'">
					<el-input-number
                        v-model="dic.sort"
                        :min="0"
                    />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
                <el-button v-if="action==='create'" type="primary" plain @click="handleSave">保存</el-button>
                <el-button v-else type="success" plain @click="handleSaveUpdate">修改</el-button>
                <el-button plain @click="handleClose">取消</el-button>
            </div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		visible: {
            type: Boolean,
            required: true,
        },
		action: {
			type: String,
			required: true
		},
		parent: {
			type: Object
		}
	},
	data() {
		return {
			categoryList: [
				'焦点图', 
				'照片墙', 
				'行业推荐', 
				'行业招聘',
				'设计师推荐', 
				'雪花系', 
				'花艺知识', 
				'VIP视频', 
				'VIP音频', 
				'VIP文档'
				],
			dic: {
				parent: {
					id: ''
				},
				code: '',
				value: '',
				category: '',
				sort: 0,
			},
			formRules: {
				code: [{ required: true, message: "请输入英文名称", trigger: 'blur' }],
				value: [{ required: true, message: "请输入中文名称", trigger: 'blur' }],
				category: [{ required: true, message: "请输入栏目", trigger: 'blur' }],
				sort: [{ required: true, message: "请输入排序", trigger: 'blur' }]
			}
		}
	},
	methods: {
		handleClose() {
			this.$emit("closeEditDialog");
		},
		handleResetForm() {
			this.dic = {
				parent: {
					id: ''
				},
				code: '',
				value: '',
				category: '',
				sort: 0
			}
		},
		handleEdit(dic) {
			this.dic = dic
		},
		handleParentRefresh() {
			this.$emit("refresh");
		},
		handleSave() {
			this.$refs.dic.validate((valid) => {
                if(valid) {
					this.dic.parent = this.parent

                    this.$api.setting.dic.add(this.dic)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleSaveUpdate() {
			this.$refs.dic.validate((valid) => {
                if(valid) {
                    this.$api.setting.dic.edit(this.dic)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		}
	}
}
</script>