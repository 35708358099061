<template>
	<div>
		<el-dialog
            :title="action==='create'?'添加图片':'编辑图片'"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="1000px"
        >
			<el-form
                label-width="120px"
                :rules="formRules"
                :model="photo"
                ref="photo"
            >
				<el-form-item label="分类" prop="category">
					<el-select v-model="photo.category.id" clearable placeholder="请选择分类">
						<el-option
							v-for="item in categoryList"
							:key="item.id"
							:label="item.value"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="图片" prop="photo">
                    <el-upload
						class="avatar-uploader"
						action=""
						:on-change="handleUploadPhoto"
                        :show-file-list="false"
						:auto-upload="false"
                    >
                        <img v-if="photo.photo.absolutePath" :src="photo.photo.absolutePath" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input
                        v-model="photo.name"
                        auto-complete="off"
                        placeholder="名称"
                    />
                </el-form-item>
				<el-form-item label="材料单" prop="desc">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="材料单"
                        v-model="photo.desc"
                    >
                    </el-input>
                </el-form-item>
				<el-form-item label="排序" prop="sort" v-if="action !== 'create'">
                    <el-input-number
                        v-model="photo.sort"
                        :min="0"
                    />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
                <el-button v-if="action==='create'" type="primary" plain @click="handleSave">保存</el-button>
                <el-button v-else type="success" plain @click="handleSaveUpdate">修改</el-button>
                <el-button plain @click="handleClose">取消</el-button>
            </div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		visible: {
            type: Boolean,
            required: true,
        },
		action: {
			type: String,
			required: true
		}
	},
	data() {
		var validatePhoto = (rule, photo, callback) => {
			if (photo.absolutePath === '') {
				callback(new Error('请上传图片'));
			} 
			else {
				callback();
			}
		};
		var validateCategory = (rule, category, callback) => {
			if (category.id === '') {
				callback(new Error('请选择分类'));
			} 
			else {
				callback();
			}
		};
		return {
			photo: {
				id: '',
				category: {
					id: ''
				},
				name: '',
				desc: '',
				photo: {
					absolutePath: '',
					relativePath: '',
					thumbPath: ''
				},
				sort: 0
			},
			categoryList: [],
			formRules: {
				category: [{required: true, validator: validateCategory}],
				name: [{required: true, message: "请输入名称", trigger: "blur"}],
				photo: [{required: true, validator: validatePhoto}],
				sort: [{required: true, message: "请输入排序", trigger: "blur"}]
			}
		}
	},
	methods: {
		handleClose() {
			this.$emit("closeEditDialog");
		},
		handleResetForm() {
			this.photo = {
				id: '',
				category: {
					id: ''
				},
				name: '',
				desc: '',
				photo: {
					absolutePath: '',
					relativePath: '',
					thumbPath: ''
				},
				sort: 0
			}
		},
		handleEdit(photo) {
			this.photo = photo
		},
		handleParentRefresh() {
			this.$emit("refresh");
		},
		handleUploadPhoto(file) {
			let fileExt = file.name.replace(/.+\./, "")

			if(['jpg', 'jpeg', 'png'].indexOf(fileExt.toLowerCase()) === -1) {
				this.$message.error('上传图片只能是JPG或PNG格式!');
				return;
			}

			this.$api.common.fileUpload.upload(file)
			.then(response => {
				if(response.code === "0") {
					this.photo.photo = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleFindAllCategory() {
			this.$api.setting.dic.findAll('', '照片墙')
			.then(response => {
				if(response.code === "0") {
					this.categoryList = response.data;
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleSave() {
			this.$refs.photo.validate((valid) => {
                if(valid) {
                    this.$api.focus.photoWall.add(this.photo)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleSaveUpdate() {
			this.$refs.photo.validate((valid) => {
                if(valid) {
                    this.$api.focus.photoWall.edit(this.photo)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		}
	},
	mounted() {
		this.handleFindAllCategory();
	}
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
}
.avatar-uploader-icon:hover {
    border-color: #409eff;
}
.avatar {
    width: 178px;
    /* height: 178px; */
    display: block;
    border: 1px dashed #d9d9d9;
}
.avatar:hover {
    border-color: #409eff;
}
</style>