<template>
	<div>
		<el-dialog
            title="消费记录"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="95%"
        >
			<el-table	
				:data="prepayList"
				border
				stripe
				class="prepayList"
				row-key="id"
			>
				<el-table-column align="center" width="90"  label="序号">
					<template slot-scope="scope">
						<span>{{ scope.$index + ( paging.pageIndex - 1 ) * paging.pageSize + 1 }}</span>   
					</template>
				</el-table-column>
				<el-table-column label="姓名" prop="user.name" width="120px" align="center" />
				<el-table-column label="联系电话" prop="user.phoneNumbers" width="120px" align="center" />
				<el-table-column label="身份证号" prop="user.idCardNo" width="180px" align="center" />
				<el-table-column label="规格" prop="spec.name" width="150px" align="center" />
				<el-table-column label="地址" prop="user.address" />
				<el-table-column label="状态" prop="checkStatus" width="100px" align="center" />
				<el-table-column label="下单时间" prop="createTime" width="170px" align="center" />
			</el-table>
			<el-pagination
				:current-page="paging.pageIndex"
				:page-sizes="[100, 200, 300, 400]"
				:page-size="paging.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="paging.recordCount" 
				class="paging"
				@current-change="handlePageIndexChanged"
				@size-change="handleSizeChange"
			/>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
        visible: {
            type: Boolean,
            required: true,
        }
    },
	data() {
		return {
			paging: {
				pageIndex: 1,
				pageSize: 100,
				recordCount: 0,
				pageCount: 0
			},
			prepayList: []
		}
	},
	methods: {
		handleClose() {
            this.$emit("closeDialog");
        },
		handleFindUserPrepayRecord(user) {
			this.$api.auth.user.findUserPrepayRecord
			(
				user.id, 
				this.paging.pageIndex, 
				this.paging.pageSize
			)
			.then(response => {
				if(response.code === '0') {
					this.prepayList = response.data.prepayList
					this.paging = response.data.paging
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handlePageIndexChanged(currentPageIndex) {
			this.paging.pageIndex = currentPageIndex;
			this.handleFindAll();
		},
		handleSizeChange(currentPageSize) {
			this.paging.pageSize = currentPageSize;
			this.paging.pageIndex = 1;
			this.handleFindAll();
		},
	},
	mounted() {
		
	}
}
</script>

<style scoped>
.prepayList {
	margin-top: 1rem;
}
.paging {
	margin: 1rem auto;
    text-align: center;
}
</style>