import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta' 
import NProgress from 'nprogress/nprogress'
import "nprogress/nprogress.css";
import store from '@/state'

import frameworkRoutes from './framework'
import protectedRoutes from './protected'

const baseRoutes = [
    
];

const routes = baseRoutes.concat(
	frameworkRoutes,
	protectedRoutes
);
Vue.use(VueRouter)
Vue.use(VueMeta, {
	// vue-meta在其上查找元信息的组件选项名(在vue文件中查找keyName:对应的属性名作为页面属性)
	keyName: 'page',
})

const router = new VueRouter({
	routes: routes,
	mode: 'hash',
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

// 路由跳转前处理
router.beforeEach((routeTo, routeFrom, next) => {
	NProgress.start()

	// 检查此路由上是否需要验证(即：检验路由中“meta: { authRequired: true }”的值)
	// (包括嵌套路由).
	const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

	// 如果路由不需要验证，则放行
	if (!authRequired) return next()

	if(store.getters["loggedIn"]) {
		store.dispatch("auth/validateToken")
		.then(response => {
			if(response.code !== "-9") {
				return next()
			}
			redirectToLogin()
		})
		.catch(error => {
			redirectToLogin()
		})
	}
	else {
		redirectToLogin()
	}

	// token无效一律跳转至登录页
	function redirectToLogin() {
		// 将原始路由传递到登录组件
		next({ name: 'login', query: { redirect: routeTo.fullPath } })
	}
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
	try {
		for (const route of routeTo.matched) {
			await new Promise((resolve, reject) => {
				if (route.meta && route.meta.beforeResolve) {
					route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
						if (args.length) {
							if (routeFrom.name === args[0].name) {
								NProgress.done()
							}
							next(...args)
							reject(new Error('Redirected'))
						} else {
							resolve()
						}
					})
				} else {
					resolve()
				}
			})
		}
	} catch (error) {
		return
	}
	next()
})

router.afterEach(() => {
	NProgress.done()
})

export default router
