<template>
	<div>
		<div class="toolbar">
			<el-form :inline="true" class="demo-form-inline">
				<el-form-item label="手机号">
					<el-input placeholder="输入手机号模糊查询" v-model="search.phoneNumbers" />
				</el-form-item>
				<el-form-item label="核销状态">
					<el-radio-group v-model="search.checkStatus" size="medium" >
						<el-radio-button label="全部" />
						<el-radio-button label="未使用" />
						<el-radio-button label="已使用" />
					</el-radio-group>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-search" round type="primary" @click="handleFindAll">查询</el-button>
				</el-form-item>
			</el-form>
        </div>
		<el-table	
			:data="prepayList"
			border
			stripe
			class="prepayList"
			row-key="id"
		>
			<el-table-column align="center" width="90"  label="序号">
				<template slot-scope="scope">
					<span>{{ scope.$index + ( paging.pageIndex - 1 ) * paging.pageSize + 1 }}</span>   
				</template>
			</el-table-column>
			<el-table-column label="姓名" prop="user.name" width="120px" align="center" />
			<el-table-column label="联系电话" prop="user.phoneNumbers" width="120px" align="center" />
			<el-table-column label="身份证号" prop="user.idCardNo" width="180px" align="center" />
			<el-table-column label="规格" prop="spec.name" width="150px" align="center" />
			<el-table-column label="地址" prop="user.address" />
			<el-table-column label="状态" prop="checkStatus" width="100px" align="center" />
			<el-table-column label="下单时间" prop="createTime" width="170px" align="center" />
			<el-table-column label="操作" width="120px" align="center">
				<template slot-scope="scope">
					<el-button type="primary" size="medium" v-if="scope.row.checkStatus === '未使用'" @click="handleCheckPrepay(scope.row)">核销</el-button>
					<el-popover
						v-else
						placement="left"
						title="核销信息"
						width="350"
						trigger="click"
						:content="`${scope.row.checkMan.nickname}(${scope.row.checkMan.account})于${scope.row.checkTime}核销`"
					>
						<el-button slot="reference" type="info" size="medium">已核销</el-button>
					</el-popover>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:current-page="paging.pageIndex"
			:page-sizes="[100, 200, 300, 400]"
			:page-size="paging.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="paging.recordCount" 
			class="paging"
			@current-change="handlePageIndexChanged"
			@size-change="handleSizeChange"
		/>
	</div>
</template>

<script>
export default {
	page: 
	{
		title: '订单管理'
	},
	data() {
		return {
			search: {
				checkStatus: '全部',
				phoneNumbers: '',
			},
			paging: {
				pageIndex: 1,
				pageSize: 100,
				recordCount: 0,
				pageCount: 0
			},
			prepayList: []
		}
	},
	methods: {
		handleFindAll() {
			this.$api.home.activity.findAllPrepay
			(
				this.search.phoneNumbers, 
				this.search.checkStatus === '全部' ? '' : this.search.checkStatus, 
				this.paging.pageIndex, 
				this.paging.pageSize
			)
			.then(response => {
				if(response.code === '0') {
					this.prepayList = response.data.prepayList
					this.paging = response.data.paging
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handlePageIndexChanged(currentPageIndex) {
			this.paging.pageIndex = currentPageIndex;
			this.handleFindAll();
		},
		handleSizeChange(currentPageSize) {
			this.paging.pageSize = currentPageSize;
			this.paging.pageIndex = 1;
			this.handleFindAll();
		},
		handleCheckPrepay(prepay) {
			this.$api.home.activity.checkPrepay(prepay)
			.then(response => {
				if(response.code === '0') {
					prepay.checkStatus = '已使用'
					this.$message.success('已核销')
				}
				else {
					this.$message.error(response.message)
				}
			})
		}
	},
	mounted() {
		this.handleFindAll()
	}
}
</script>

<style scoped>
.toolbar .el-input-group {
    margin-left: 10px;
    width: 300px;
}
.prepayList {
	margin-top: 1rem;
}
.paging {
	margin: 1rem auto;
    text-align: center;
}
</style>