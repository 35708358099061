import request from "@/utils/request"

export default {
	findAll(phoneNumbers, pageIndex, pageSize) {
		let queryString = `phoneNumbers=${phoneNumbers}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/auth/user/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取会员列表，请稍候..."
				}
			}
		});	
	},
	gradeUpOrDown(user) {
		return request({
			url: `/api/auth/user/gradeUpOrDown`,
			method: "post",
			data: user,
			options: {
                loading: {
					show: false
				}
			}
		});
	},
	findUserPrepayRecord(userId, pageIndex, pageSize) {
		let queryString = `userId=${userId}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/auth/user/findUserPrepayRecord?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取会员消费记录，请稍候..."
				}
			}
		});	
	},
	editAdminRemark(user) {
		return request({
			url: `/api/auth/user/editAdminRemark`,
			method: "post",
			data: user,
			options: {
                loading: {
					show: false
				}
			}
		});
	},
	removeUser(user) {
		return request({
			url: `/api/auth/user/removeUser`,
			method: "post",
			data: user,
			options: {
                loading: {
					show: true,
					message: "正在删除会员信息，请稍候..."
				}
			}
		});
	},
	addUser(user) {
		return request({
			url: `/api/auth/user/addUser`,
			method: "post",
			data: user,
			options: {
                loading: {
					show: true,
					message: "正在保存会员信息，请稍候..."
				}
			}
		});
	},
	changeBlackList(id, blackList) {
		return request({
			url: `/api/auth/user/changeBlackList`,
			method: "post",
			data: { id, blackList },
			options: {
                loading: {
					show: true,
					message: `更新状态中,请稍候...`
				}
			}
		});	
	},
	getUserVideoRights(user) {
		return request({
			url: `/api/auth/user/getUserVideoRights?user=${user}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取会员视频权限，请稍候..."
				}
			}
		});	
	},
	changeUserVideoRights(user) {
		return request({
			url: `/api/auth/user/changeUserVideoRights`,
			method: "post",
			data: user,
			options: {
                loading: {
					show: true,
					message: `正在更新会员权限,请稍候...`
				}
			}
		});	
	}
}