<template>
	<div>
		<el-dialog
            :title="action==='create'?'添加VIP文档':'编辑VIP文档'"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="800px"
        >
			<el-form
                label-width="120px"
                :rules="formRules"
                :model="document"
                ref="document"
            >
				<el-form-item label="分类" prop="category">
					<el-select v-model="document.category.id" placeholder="请选择">
						<el-option
							v-for="category in categoryList"
							:key="category.id"
							:label="category.value"
							:value="category.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="名称" prop="name">
                    <el-input
                        v-model="document.name"
                        auto-complete="off"
                        placeholder="名称"
						style="width: 600px"
                    />
				</el-form-item>
				<el-form-item label="资源" prop="oss">
                    <el-upload
						class="avatar-uploader"
						action=""
						:on-change="handleUploadOss"
                        :show-file-list="false"
						:auto-upload="false"
                    >
						<el-input
							v-if="document.oss.objectName"
							auto-complete="off"
							v-model="document.oss.objectName"
							placeholder="阿里云存储地址"
							style="width: 600px; cursor: pointer;"
							readonly
						/>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
					<el-progress 
						:percentage="uploadProcessStatus.document.loadProgress" 
						color="#409eff" 
						v-if="uploadProcessStatus.document.showProgress === true"
					/>
                </el-form-item>
				<el-form-item label="排序" prop="sort" v-if="action !== 'create'">
					<el-input-number
                        v-model="document.sort"
                        :min="0"
                    />
				</el-form-item>
				<el-form-item label="介绍">
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="介绍"
                        v-model="document.desc"
                    >
                    </el-input>
                </el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
                <el-button v-if="action==='create'" type="primary" plain @click="handleSave">保存</el-button>
                <el-button v-else type="success" plain @click="handleSaveUpdate">修改</el-button>
                <el-button plain @click="handleClose">取消</el-button>
            </div>
		</el-dialog>
	</div>
</template>

<script>
import { client } from '@/utils/aliyun-oss';
import moment from 'moment';
import guid from '@/utils/guid';
export default {
	props: {
        visible: {
            type: Boolean,
            required: true,
        },
		action: {
			type: String,
			required: true
		}
    },
	data() {
		var validateCategory = (rule, category, callback) => {
			if (category.id === '') {
				callback(new Error('请选择分类'));
			} 
			else {
				callback();
			}
		};
		var validateOss = (rule, oss, callback) => {
			if (oss.objectName === '') {
				callback(new Error('请上传资源文件'));
			} 
			else {
				callback();
			}
		};
		return {
			categoryList: [],
			formRules: {
				category: [{validator: validateCategory, required: true, trigger: "blur"}],
				name: [{required: true, message: "请输入名称", trigger: "blur"}],
				oss: [{validator: validateOss, required: true}],
				sort: [{required: true, message: "请输入排序", trigger: "blur"}],
			},
			aliyunOss: {
				accessKeyId: '',
				accessKeySecret: '',
				bucketName: '',
				endpoint: ''
			},
			document: {
				category: {
					id: ''
				},
				name: '',
				oss: {
					objectName: '',
					suffixName: '',
					fileSize: ''
				},
				desc: '',
				sort: 0
			},
			// 显示文件上传进度
			uploadProcessStatus: {
				document: {
					showProgress: false,
					loadProgress: 0
				}
			}
		}
	},
	methods: {
		handleClose() {
			this.handleResetForm();
            this.$emit("closeEditDialog");
		},
		handleResetForm() {
			this.document = {
				category: {
					id: ''
				},
				name: '',
				oss: {
					objectName: '',
					suffixName: '',
					fileSize: ''
				},
				desc: '',
				sort: 0
			}

			this.uploadProcessStatus = {
				document: {
					showProgress: false,
					loadProgress: 0
				}
			}
		},
		handleEdit(document) {
			this.document = document
		},
		handleSave() {
			this.$refs.document.validate((valid) => {
                if(valid) {
                    this.$api.aliyun.document.add(this.document)
					.then(response => {
						if(response.code === '0') {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error(response.error)
						}
					})
                }
            });
		},
		handleSaveUpdate() {
			this.$refs.document.validate((valid) => {
                if(valid) {
                    this.$api.aliyun.document.edit(this.document)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleParentRefresh() {
			this.$emit("refresh");
		},
		handleFindAllCategory() {
			this.$api.setting.dic.findAll('', 'VIP文档')
			.then(response => {
				if(response.code === "0") {
					this.categoryList = response.data;
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleUploadOss(file) {
			// 限制上传的视频文件格式
			// let fileExt = file.name.replace(/.+\./, "")

			// if(['mp4', 'vod'].indexOf(fileExt.toLowerCase()) === -1) {
			// 	this.$message.error('上传文档只能是...格式!');
			// 	return;
			// }

			let that = this
			
			let fileData = new FormData();
			fileData.append('file', file.raw); //传文件

			this.uploadProcessStatus.document.showProgress = true
			this.uploadProcessStatus.document.loadProgress = 0

			let fileS = file.size

			if (fileS === 0) {
				this.$message.error('无效的空文件')
				return
			}

			let k = 1024
			let sizes = ['B','KB','MB','GB','PB','TB','EB','ZB','YB']

			let i = Math.floor(Math.log(fileS) / Math.log(k));

			let localDate = moment().format("YYYY-MM-DD")
			let suffix = file.name.substring(file.name.lastIndexOf('.')+1)
			let fileSize = (fileS / Math.pow(k,i)).toPrecision(3) + "" + sizes[i]

			async function multipartUpload () {
						
				// 通过服务器接口获取文件类型并拼接文件上传路径
				const fileName = `${localDate}/${suffix}/${guid.newGuid()}.${suffix}`

				//定义唯一的文件名，打印出来的uid其实就是时间戳
				//client 是第一步中的 client
				client(that.aliyunOss).multipartUpload(fileName, file.raw,
				{
					progress:function (p) { 
						//获取进度条的值
						that.uploadProcessStatus.document.loadProgress = Math.ceil(p * 100)
					},
				})
				.then(result => {
				//下面是如果对返回结果再进行处理，根据项目需要
					that.document.oss = {
						objectName: fileName,
						suffixName: suffix,
						fileSize: fileSize
					}
				})
				.catch(err => {
					that.$message.error(err)
				})
			}

			multipartUpload()
		},
		handleGetAliyunOssAcountInfo() {
			this.$api.common.aliyunOssFileUpload.getAliyunOssAcountInfo()
			.then(response => {
				if(response.code === "0") {
					this.aliyunOss = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		}
	},
	mounted() {
		this.handleGetAliyunOssAcountInfo()
		this.handleFindAllCategory()
	}
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
}
.avatar-uploader-icon:hover {
    border-color: #409eff;
}
.avatar {
    width: 178px;
    /* height: 178px; */
    display: block;
    border: 1px dashed #d9d9d9;
}
.avatar:hover {
    border-color: #409eff;
}
</style>