import request from "@/utils/request"

export default {
	add(material) {
		return request({
			url: `/api/focus/photoWallMaterial/add`,
			method: "post",
			data: material,
			options: {
                loading: {
					show: true,
					message: "正在保存材料信息，请稍候..."
				}
			}
		});
	},
	remove(materialList) {
		return request({
			url: `/api/focus/photoWallMaterial/remove`,
			method: "post",
			data: materialList,
			options: {
                loading: {
					show: true,
					message: "正在删除材料信息，请稍候..."
				}
			}
		});
	},
	edit(material) {
		return request({
			url: `/api/focus/photoWallMaterial/edit`,
			method: "post",
			data: material,
			options: {
                loading: {
					show: true,
					message: "正在保存材料信息，请稍候..."
				}
			}
		});
	},
	findAll(photo) {
		let queryString = `photo=${photo}`

		return request({
			url: `/api/focus/photoWallMaterial/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取图片列表，请稍候..."
				}
			}
		});	
	},
}