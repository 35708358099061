import request from "@/utils/request"

export default {
	login(account, password) {
		return request({
			url: `/api/auth/administrator/login?account=${account}&password=${password}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "登录中，请稍候..."
				}
			}
		});
	},
	validateToken() {
		return request({
			url: `/api/auth/administrator/validateToken`,
			method: "get"
		});
	},
	add(administrator) {
		return request({
			url: `/api/auth/administrator/add`,
			method: "post",
			data: administrator,
			options: {
                loading: {
					show: true,
					message: "正在保存管理员信息，请稍候..."
				}
			}
		});
	},
	edit(administrator) {
		return request({
			url: `/api/auth/administrator/edit`,
			method: "post",
			data: administrator,
			options: {
                loading: {
					show: true,
					message: "正在保存管理员信息，请稍候..."
				}
			}
		});
	},
	remove(administratorList) {
		return request({
			url: `/api/auth/administrator/remove`,
			method: "post",
			data: administratorList,
			options: {
                loading: {
					show: true,
					message: "正在批量删除管理员信息，请稍候..."
				}
			}
		});
	},
	findAll(account, pageIndex, pageSize) {
		let queryString = `account=${account}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/auth/administrator/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取管理员列表，请稍候..."
				}
			}
		});	
	},
	changeEnabled(id, enabled) {
		return request({
			url: `/api/auth/administrator/changeEnabled`,
			method: "post",
			data: { id, enabled },
			options: {
                loading: {
					show: true,
					message: `更新状态中,请稍候...`
				}
			}
		});	
	},
}