import request from "@/utils/request"

export default {
	add(document) {
		return request({
			url: `/api/aliyun/document/add`,
			method: "post",
			data: document,
			options: {
                loading: {
					show: true,
					message: "正在保存文档信息，请稍候..."
				}
			}
		});
	},
	findAll(name, pageIndex, pageSize) {
		let queryString = `name=${name}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/aliyun/document/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取文档列表，请稍候..."
				}
			}
		});	
	},
	remove(documentList) {
		return request({
			url: `/api/aliyun/document/remove`,
			method: "post",
			data: documentList,
			options: {
                loading: {
					show: true,
					message: "正在批量删除文档信息，请稍候..."
				}
			}
		});
	},
	edit(document) {
		return request({
			url: `/api/aliyun/document/edit`,
			method: "post",
			data: document,
			options: {
                loading: {
					show: true,
					message: "正在保存文档信息，请稍候..."
				}
			}
		});
	},
	transposition(documentList) {
		return request({
			url: `/api/aliyun/document/transposition`,
			method: "post",
			data: documentList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	},
	
}