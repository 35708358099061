import request from "@/utils/request"

export default {
	add(activity) {
		return request({
			url: `/api/home/activity/add`,
			method: "post",
			data: activity,
			options: {
                loading: {
					show: true,
					message: "正在保存活动信息，请稍候..."
				}
			}
		});
	},
	edit(activity) {
		return request({
			url: `/api/home/activity/edit`,
			method: "post",
			data: activity,
			options: {
                loading: {
					show: true,
					message: "正在保存活动信息，请稍候..."
				}
			}
		});
	},
	findAll(name, pageIndex, pageSize) {
		let queryString = `name=${name}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/home/activity/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取活动列表，请稍候..."
				}
			}
		});	
	},
	changeEnabled(id, enabled) {
		return request({
			url: `/api/home/activity/changeEnabled`,
			method: "post",
			data: { id, enabled },
			options: {
                loading: {
					show: true,
					message: `${enabled?'上':'下'}架中,请稍候...`
				}
			}
		});	
	},
	remove(activityList) {
		return request({
			url: `/api/home/activity/remove`,
			method: "post",
			data: activityList,
			options: {
                loading: {
					show: true,
					message: "正在删除活动，请稍候..."
				}
			}
		});	
	},
	getSingle(id) {
		return request({
			url: `/api/home/activity/getSingle?id=${id}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取活动信息，请稍候..."
				}
			}
		});	
	},
	removeSpec(spec) {
		return request({
			url: `/api/home/activity/removeSpec`,
			method: "post",
			data: spec,
			options: {
                loading: {
					show: true,
					message: "正在删除规格，请稍候..."
				}
			}
		});	
	},
	transposition(activityList) {
		return request({
			url: `/api/home/activity/transposition`,
			method: "post",
			data: activityList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	},
	findAllPrepay(phoneNumbers, checkStatus, pageIndex, pageSize) {
		let queryString = `phoneNumbers=${phoneNumbers}&checkStatus=${checkStatus}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/home/activity/findAllPrepay?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取订单列表，请稍候..."
				}
			}
		});	
	},
	checkPrepay(prepay) {
		return request({
			url: `/api/home/activity/checkPrepay`,
			method: "post",
			data: prepay,
			options: {
                loading: {
					show: true,
					message: "正在核销订单，请稍候..."
				}
			}
		});	
	}
}