import request from "@/utils/request"

export default {
	add(industry) {
		return request({
			url: `/api/commend/industry/add`,
			method: "post",
			data: industry,
			options: {
                loading: {
					show: true,
					message: "正在保存行业推荐信息，请稍候..."
				}
			}
		});
	},
	edit(industry) {
		return request({
			url: `/api/commend/industry/edit`,
			method: "post",
			data: industry,
			options: {
                loading: {
					show: true,
					message: "正在保存行业推荐信息，请稍候..."
				}
			}
		});
	},
	remove(industryList) {
		return request({
			url: `/api/commend/industry/remove`,
			method: "post",
			data: industryList,
			options: {
                loading: {
					show: true,
					message: "正在批量删除行业推荐信息，请稍候..."
				}
			}
		});
	},
	findAll(shopName, pageIndex, pageSize) {
		let queryString = `shopName=${shopName}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/commend/industry/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取行业推荐列表，请稍候..."
				}
			}
		});	
	},
	transposition(industryList) {
		return request({
			url: `/api/commend/industry/transposition`,
			method: "post",
			data: industryList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	}
}