const state = {
	title: 'c-floarl',
	sidebar: {
		open: true,
	},
	tipbar: {
		open: false,
	},
	sync: true,
}

const mutations = {
	TOGGLE_SIDEBAR: state => {
		state.sidebar.open = !state.sidebar.open;
	},
	TOGGLE_TIPBAR: state => {
		state.tipbar.open = !state.tipbar.open;
	},
	SYNC: state => {
		state.sync = !state.sync;
	}
}

export {
	state,
	mutations
}
