import request from "@/utils/request"

export default {
	save(about) {
		return request({
			url: `/api/contact/aboutUs/save`,
			method: "post",
			data: about,
			options: {
                loading: {
					show: true,
					message: "正在保存信息，请稍候..."
				}
			}
		});
	},
	getSingle() {
		return request({
			url: `/api/contact/aboutUs/getSingle`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取信息，请稍候..."
				}
			}
		});	
	}
}