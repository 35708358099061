<template>
	<div>
		<el-dialog
            :title="action==='create'?'添加行业推荐信息':'编辑行业推荐信息'"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="1000px"
        >
			<el-form
                label-width="120px"
                :rules="formRules"
                :model="industry"
                ref="industry"
            >
				<el-form-item label="分类" prop="category">
					<el-select v-model="industry.category.id" clearable placeholder="请选择分类">
						<el-option
							v-for="item in categoryList"
							:key="item.id"
							:label="item.value"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="店铺名称" prop="shopName">
                    <el-input
                        v-model="industry.shopName"
                        auto-complete="off"
                        placeholder="店铺名称"
                    />
                </el-form-item>
				<el-form-item label="经营类目" prop="businessCategory">
                    <el-input
                        v-model="industry.businessCategory"
                        auto-complete="off"
                        placeholder="经营类目"
                    />
                </el-form-item>
				<el-form-item label="微信" prop="wechat">
                    <el-input
                        v-model="industry.wechat"
                        auto-complete="off"
                        placeholder="微信"
                    />
                </el-form-item>
				<el-form-item label="淘宝" prop="taobao">
                    <el-input
                        v-model="industry.taobao"
                        auto-complete="off"
                        placeholder="淘宝"
                    />
                </el-form-item>
				<el-form-item label="抖音" prop="douyin">
                    <el-input
                        v-model="industry.douyin"
                        auto-complete="off"
                        placeholder="抖音"
                    />
                </el-form-item>
				<el-form-item label="工作时间" prop="workTime">
                    <el-input
                        v-model="industry.workTime"
                        auto-complete="off"
                        placeholder="工作时间"
                    />
                </el-form-item>
				<el-form-item label="宣传图" prop="thumbnail">
                    <el-upload
						class="avatar-uploader"
						action=""
						:on-change="handleUploadThumbnail"
                        :show-file-list="false"
						:auto-upload="false"
                    >
                        <img v-if="industry.thumbnail.absolutePath" :src="industry.thumbnail.absolutePath" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
				<el-form-item label="排序" prop="sort" v-if="action !== 'create'">
                    <el-input-number
                        v-model="industry.sort"
                        :min="0"
                    />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
                <el-button v-if="action==='create'" type="primary" plain @click="handleSave">保存</el-button>
                <el-button v-else type="success" plain @click="handleSaveUpdate">修改</el-button>
                <el-button plain @click="handleClose">取消</el-button>
            </div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		visible: {
            type: Boolean,
            required: true,
        },
		action: {
			type: String,
			required: true
		}
	},
	data() {
		var validateCategory = (rule, category, callback) => {
			if (category.id === '') {
				callback(new Error('请选择分类'));
			} 
			else {
				callback();
			}
		};
		var validateThumbnail = (rule, thumbnail, callback) => {
			if (thumbnail.absolutePath === '') {
				callback(new Error('请上传宣传图'));
			} 
			else {
				callback();
			}
		};
		return {
			industry: {
				id: '',
				category: {
					id: ''
				},
				shopName: '',
				businessCategory: '',
				wechat: '',
				taobao: '',
				douyin: '',
				workTime: '',
				thumbnail: {
					absolutePath: '',
					relativePath: ''
				},
				sort: 0
			},
			formRules: {
				category: [{required:true, validator: validateCategory}],
				shopName: [{required:true, message: '请输入店铺名称', trigger: 'blur'}],
				businessCategory: [{required:true, message: '请输入经营类目', trigger: 'blur'}],
				workTime: [{required:true, message: '请输入工作时间', trigger: 'blur'}],
				thumbnail: [{required:true, validator: validateThumbnail}],
				sort: [{required:true, message: '请输入排序', trigger: 'blur'}],
			},
			categoryList: []
		}
	},
	methods: {
		handleClose() {
			this.$emit("closeEditDialog");
		},
		handleResetForm() {
			this.industry = {
				id: '',
				category: {
					id: ''
				},
				shopName: '',
				businessCategory: '',
				wechat: '',
				taobao: '',
				douyin: '',
				workTime: '',
				thumbnail: {
					absolutePath: '',
					relativePath: ''
				},
				sort: 0
			}
		},
		handleFindAllCategory() {
			this.$api.setting.dic.findAll('', '行业推荐')
			.then(response => {
				if(response.code === "0") {
					this.categoryList = response.data;
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleUploadThumbnail(file) {
			let fileExt = file.name.replace(/.+\./, "")

			if(['jpg', 'jpeg', 'png'].indexOf(fileExt.toLowerCase()) === -1) {
				this.$message.error('上传图片只能是JPG或PNG格式!');
				return;
			}

			this.$api.common.fileUpload.upload(file)
			.then(response => {
				if(response.code === "0") {
					this.industry.thumbnail = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleParentRefresh() {
			this.$emit("refresh");
		},
		handleSave() {
			this.$refs.industry.validate((valid) => {
                if(valid) {
                    this.$api.commend.industry.add(this.industry)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleSaveUpdate() {
			this.$refs.industry.validate((valid) => {
                if(valid) {
                    this.$api.commend.industry.edit(this.industry)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleEdit(industry) {
			this.industry = industry
		},
	},
	mounted() {
		this.handleFindAllCategory()
	}
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
}
.avatar-uploader-icon:hover {
    border-color: #409eff;
}
.avatar {
    width: 178px;
    /* height: 178px; */
    display: block;
    border: 1px dashed #d9d9d9;
}
.avatar:hover {
    border-color: #409eff;
}
</style>