<template>
	<div>
		<el-dialog
            :title="action==='create'?'添加':'编辑'"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="1000px"
        >
			<el-form
                label-width="120px"
                :rules="formRules"
                :model="snowflake"
                ref="snowflake"
            >
				<el-form-item label="分类" prop="category">
					<el-select v-model="snowflake.category.id" clearable placeholder="请选择">
						<el-option
							v-for="category in categoryList"
							:key="category.id"
							:label="category.value"
							:value="category.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标题" prop="title">
                    <el-input
                        v-model="snowflake.title"
                        auto-complete="off"
                        placeholder="标题"
                    />
				</el-form-item>
				<el-form-item label="副标题" prop="subTitle">
                    <el-input
                        v-model="snowflake.subTitle"
                        auto-complete="off"
                        placeholder="副标题"
                    />
				</el-form-item>
				<el-form-item label="头像" prop="headImage">
                    <el-upload
						class="avatar-uploader"
						action=""
						:on-change="handleUploadHeadImage"
                        :show-file-list="false"
						:auto-upload="false"
                    >
                        <img v-if="snowflake.headImage.absolutePath" :src="snowflake.headImage.absolutePath" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
				<el-form-item label="宣传图" prop="thumbnail">
                    <el-upload
						class="avatar-uploader"
						action=""
						:on-change="handleUploadThumbnail"
                        :show-file-list="false"
						:auto-upload="false"
                    >
                        <img v-if="snowflake.thumbnail.absolutePath" :src="snowflake.thumbnail.absolutePath" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
				<el-form-item label="介绍" prop="content">
					<quill-editor
                        :options="editorOption"
                        v-model="snowflake.content"
                    >
                    </quill-editor>
				</el-form-item>
				<el-form-item label="排序" prop="sort" v-if="action !== 'create'">
					<el-input-number
                        v-model="snowflake.sort"
                        :min="0"
                    />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
                <el-button v-if="action==='create'" type="primary" plain @click="handleSave">保存</el-button>
                <el-button v-else type="success" plain @click="handleSaveUpdate">修改</el-button>
                <el-button plain @click="handleClose">取消</el-button>
            </div>
		</el-dialog>
	</div>
</template>

<script>
/* begin 富文本编辑器 */
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import quill from "quill";
import { quillEditor } from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
quill.register("modules/imageDrop", ImageDrop);
quill.register("modules/imageResize", ImageResize);
/* end 富文本编辑器 */
export default {
	props: {
		visible: {
            type: Boolean,
            required: true,
        },
		action: {
			type: String,
			required: true
		}
	},
	components: {
        quillEditor,
    },
	data() {
		var validateCategory = (rule, category, callback) => {
			if (category.id === '') {
				callback(new Error('请选择分类'));
			} 
			else {
				callback();
			}
		};
		var validateHeadImage = (rule, headImage, callback) => {
			if (headImage.absolutePath === '') {
				callback(new Error('请上传头像'));
			} 
			else {
				callback();
			}
		};
		var validateThumbnail = (rule, thumbnail, callback) => {
			if (thumbnail.absolutePath === '') {
				callback(new Error('请上传宣传图'));
			} 
			else {
				callback();
			}
		};
		return {
			editorOption: {
                debug: false,
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike", "image"],
                        ["formula", "clean"],
                        ["blockquote", "code-block"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ script: "sub" }, { script: "super" }],
                        [{ size: ["small", false, "large", "huge"] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        [{ direction: "rtl" }],
                    ],
                    history: {
                        delay: 1000,
                        maxStack: 50,
                        userOnly: false,
                    },
                    imageDrop: true,
                    imageResize: {
                        displayStyles: {
                            backgroundColor: "black",
                            border: "none",
                            color: "white",
                        },
                        modules: ["Resize", "DisplaySize", "Toolbar"],
                    },
                },
                placeholder: "输入内容........",
            },
			snowflake: {
				category: {
					id: ''
				},
				title: '',
				subTitle: '',
				headImage: {
					absolutePath: "",
					relativePath: ""
				},
				thumbnail: {
					absolutePath: "",
					relativePath: ""
				},
				content: "",
				sort: 0
			},
			formRules: {
				category: [{validator: validateCategory, required: true, trigger: "blur"}],
				title: [{required: true, message: "请输入标题", trigger: "blur"}],
				subTitle: [{required: true, message: "请输入副标题", trigger: "blur"}],
				headImage: [{validator: validateHeadImage, required: true}],
				thumbnail: [{validator: validateThumbnail, required: true}],
				content: [{required: true, message: "请输入介绍内容", trigger: "blur"}],
				sort: [{required: true, message: "请输入排序", trigger: "blur"}],
			},
			categoryList: [],
		}
	},
	methods: {
		handleClose() {
			this.$emit("closeEditDialog");
		},
		handleResetForm() {
			this.snowflake = {
				category: {
					id: ''
				},
				title: '',
				subTitle: '',
				headImage: {
					absolutePath: "",
					relativePath: ""
				},
				thumbnail: {
					absolutePath: "",
					relativePath: ""
				},
				content: "",
				sort: 0
			}
		},
		handleEdit(snowflake) {
			this.snowflake = snowflake
		},
		handleSave() {
			this.$refs.snowflake.validate((valid) => {
                if(valid) {
                    this.$api.home.snowflake.add(this.snowflake)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleSaveUpdate() {
			this.$refs.snowflake.validate((valid) => {
                if(valid) {
                    this.$api.home.snowflake.edit(this.snowflake)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleUploadHeadImage(file) {
			let fileExt = file.name.replace(/.+\./, "")

			if(['jpg', 'jpeg', 'png'].indexOf(fileExt.toLowerCase()) === -1) {
				this.$message.error('上传图片只能是JPG或PNG格式!');
				return;
			}

			this.$api.common.fileUpload.upload(file)
			.then(response => {
				if(response.code === "0") {
					this.snowflake.headImage = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleUploadThumbnail(file) {
			let fileExt = file.name.replace(/.+\./, "")

			if(['jpg', 'jpeg', 'png'].indexOf(fileExt.toLowerCase()) === -1) {
				this.$message.error('上传图片只能是JPG或PNG格式!');
				return;
			}

			this.$api.common.fileUpload.upload(file)
			.then(response => {
				if(response.code === "0") {
					this.snowflake.thumbnail = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleFindAllCategory() {
			this.$api.setting.dic.findAll('', '雪花系')
			.then(response => {
				if(response.code === "0") {
					this.categoryList = response.data;
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleParentRefresh() {
			this.$emit("refresh");
		},
	},
	mounted() {
		this.handleFindAllCategory()
	}
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
}
.avatar-uploader-icon:hover {
    border-color: #409eff;
}
.avatar {
    width: 178px;
    /* height: 178px; */
    display: block;
    border: 1px dashed #d9d9d9;
}
.avatar:hover {
    border-color: #409eff;
}
::v-deep .ql-container {
    height: 66vh;
}
</style>