import request from "@/utils/request"

export default {
	add(industryRecruit) {
		return request({
			url: `/api/commend/industryRecruit/add`,
			method: "post",
			data: industryRecruit,
			options: {
                loading: {
					show: true,
					message: "正在保存行业招聘信息，请稍候..."
				}
			}
		});
	},
	edit(industryRecruit) {
		return request({
			url: `/api/commend/industryRecruit/edit`,
			method: "post",
			data: industryRecruit,
			options: {
                loading: {
					show: true,
					message: "正在保存行业招聘信息，请稍候..."
				}
			}
		});
	},
	remove(industryRecruitList) {
		return request({
			url: `/api/commend/industryRecruit/remove`,
			method: "post",
			data: industryRecruitList,
			options: {
                loading: {
					show: true,
					message: "正在批量删除行业招聘信息，请稍候..."
				}
			}
		});
	},
	findAll(shopName, pageIndex, pageSize) {
		let queryString = `shopName=${shopName}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/commend/industryRecruit/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取行业招聘列表，请稍候..."
				}
			}
		});	
	},
	transposition(industryRecruitList) {
		return request({
			url: `/api/commend/industryRecruit/transposition`,
			method: "post",
			data: industryRecruitList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	}
}