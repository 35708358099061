import request from "@/utils/request"

export default {
	add(video) {
		return request({
			url: `/api/aliyun/video/add`,
			method: "post",
			data: video,
			options: {
                loading: {
					show: true,
					message: "正在保存视频信息，请稍候..."
				}
			}
		});
	},
	findAll(courseName, pageIndex, pageSize) {
		let queryString = `courseName=${courseName}&pageIndex=${pageIndex}&pageSize=${pageSize}`

		return request({
			url: `/api/aliyun/video/findAll?${queryString}`,
			method: "get",
			options: {
                loading: {
					show: true,
					message: "正在获取视频列表，请稍候..."
				}
			}
		});	
	},
	remove(videoList) {
		return request({
			url: `/api/aliyun/video/remove`,
			method: "post",
			data: videoList,
			options: {
                loading: {
					show: true,
					message: "正在批量删除视频信息，请稍候..."
				}
			}
		});
	},
	edit(video) {
		return request({
			url: `/api/aliyun/video/edit`,
			method: "post",
			data: video,
			options: {
                loading: {
					show: true,
					message: "正在保存视频信息，请稍候..."
				}
			}
		});
	},
	transposition(videoList) {
		return request({
			url: `/api/aliyun/video/transposition`,
			method: "post",
			data: videoList,
			options: {
                loading: {
					show: true,
					message: "正在互换位置，请稍候..."
				}
			}
		});	
	}
}