<template>
	<div>
		<el-dialog
            :title="action==='create'?'添加VIP视频':'编辑VIP视频'"
            :visible="visible"
            :modal-append-to-body="false"
			append-to-body
            :before-close="handleClose"
			:close-on-click-modal="false"
            width="800px"
        >
			<el-form
                label-width="120px"
                :rules="formRules"
                :model="video"
                ref="video"
            >
				<el-form-item label="分类" prop="category">
					<el-select v-model="video.category.id" placeholder="请选择">
						<el-option
							v-for="category in categoryList"
							:key="category.id"
							:label="category.value"
							:value="category.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="课程名称" prop="courseName">
                    <el-input
                        v-model="video.courseName"
                        auto-complete="off"
                        placeholder="课程名称"
						style="width: 600px"
                    />
				</el-form-item>
				<el-form-item label="讲师" prop="docent">
                    <el-input
                        v-model="video.docent"
                        auto-complete="off"
                        placeholder="讲师"
						style="width: 600px"
                    />
				</el-form-item>
				<el-form-item label="封面图" prop="cover">
                    <el-upload
						class="avatar-uploader"
						action=""
						:on-change="handleUploadCover"
                        :show-file-list="false"
						:auto-upload="false"
                    >
                        <img v-if="video.cover.absolutePath" :src="video.cover.absolutePath" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
				<el-form-item label="接入区域">
					<el-select v-model="video.vod.regionId" placeholder="请选择" disabled>
						<el-option
							v-for="region in regionList"
							:key="region.value"
							:label="region.label"
							:value="region.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="视频上传" prop="vod">
					<input type="file" id="fileUpload" @change="fileChange($event)" ref="fileUpload" />
					<span class="status">{{aliyunVod.statusText}}</span>
					<el-progress 
						:percentage="uploadProcessStatus.video.loadProgress" 
						color="#409eff" 
						v-if="uploadProcessStatus.video.loadProgress > 0"
					/>
                </el-form-item>
				<el-form-item label="视频播放地址">
					<el-input
						auto-complete="off"
						v-model="video.vod.filePlayUrl"
						placeholder="视频播放地址"
						style="width: 600px; cursor: pointer;"
					/>
				</el-form-item>
				<el-form-item>
					<el-button 
						type="primary" 
						icon="el-icon-upload" 
						size="small" 
						:disabled="aliyunVod.uploadDisabled"
						@click="authUpload"
					>
						上传
					</el-button>
				</el-form-item>
				<el-form-item label="排序" prop="sort" v-if="action !== 'create'">
					<el-input-number
                        v-model="video.sort"
                        :min="0"
                    />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
                <el-button v-if="action==='create'" type="primary" plain @click="handleSave">保存</el-button>
                <el-button v-else type="success" plain @click="handleSaveUpdate">修改</el-button>
                <el-button plain @click="handleClose">取消</el-button>
            </div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
        visible: {
            type: Boolean,
            required: true,
        },
		action: {
			type: String,
			required: true
		}
    },
	data() {
		var validateCategory = (rule, category, callback) => {
			if (category.id === '') {
				callback(new Error('请选择分类'));
			} 
			else {
				callback();
			}
		};
		var validateCover = (rule, cover, callback) => {
			if (cover.absolutePath === '') {
				callback(new Error('请上传封面图'));
			} 
			else {
				callback();
			}
		};
		var validateVod = (rule, vod, callback) => {
			if (vod.fileId === '') {
				callback(new Error('请上传视频文件'));
			} 
			else {
				callback();
			}
		};
		return {
			categoryList: [],
			formRules: {
				category: [{validator: validateCategory, required: true, trigger: "blur"}],
				courseName: [{required: true, message: "请输入课程名称", trigger: "blur"}],
				docent: [{required: true, message: "请输入讲师信息", trigger: "blur"}],
				cover: [{validator: validateCover, required: true}],
				vod: [{validator: validateVod, required: true}],
				sort: [{required: true, message: "请输入排序", trigger: "blur"}],
			},
			regionList: [
				{ label: '上海', value: 'cn-shanghai' },
				{ label: '北京', value: 'cn-beijing' },
				{ label: '杭州', value: 'cn-hangzhou' },
				{ label: '深圳', value: 'cn-shenzhen' }
			],
			video: {
				category: {
					id: ''
				},
				courseName: '', //课程名称
				docent: '', //讲师
				cover: //封面
				{
					absolutePath: "",
					relativePath: ""
				},
				vod: //视频
				{
					title: '',
					regionId: 'cn-beijing',
					fileId: '',
					filePlayUrl: ''
				},
				sort: 0
			},
			// 显示文件上传进度
			uploadProcessStatus: {
				video: {
					loadProgress: 0
				}
			},
			aliyunVod: {
				uploader: null,
				timeout: '',
				partSize: '',
				parallel: '',
				retryCount: '',
        		retryDuration: '',
				userId: '1101429610577715',
				file: null,
				uploadDisabled: true,
				resumeDisabled: true,
				pauseDisabled: true,
				statusText: ''
			},
		}
	},
	methods: {
		fileChange (e) {
			let file = e.target.files[0]
			
			if (!file) {
				this.$message.info("请先选择需要上传的文件!")
				this.aliyunVod.statusText = ''
				return
			}

			var userData = '{"Vod":{}}'

			if (this.aliyunVod.uploader) {
				this.aliyunVod.statusText = ""
			}

			this.aliyunVod.uploader = this.createUploader()

			this.aliyunVod.uploader.addFile(file, null, null, null, userData)

			this.uploadProcessStatus.video.loadProgress = 0
			this.aliyunVod.uploadDisabled = false
			this.aliyunVod.pauseDisabled = true
			this.aliyunVod.resumeDisabled = true
		},
		authUpload () {
			// 然后调用 startUpload 方法, 开始上传
			if (this.aliyunVod.uploader !== null) {
				if(this.video.courseName === '') {
					this.$message.info('课程名称不能为空')
					return
				}

				this.aliyunVod.uploader.startUpload()
				this.aliyunVod.uploadDisabled = true
				this.aliyunVod.pauseDisabled = false
			}
		},
		createUploader (type) {
			let self = this

			let uploader = new AliyunUpload.Vod({
				timeout: self.aliyunVod.timeout || 60000,
				partSize: self.aliyunVod.partSize || 1048576,
				parallel: self.aliyunVod.parallel || 5,
				retryCount: self.aliyunVod.retryCount || 3,
				retryDuration: self.aliyunVod.retryDuration || 2,
				region: self.video.vod.regionId,
				userId: self.aliyunVod.userId,
				// 添加文件成功
				addFileSuccess: function (uploadInfo) {
					self.aliyunVod.uploadDisabled = false
					self.aliyunVod.resumeDisabled = false
					self.aliyunVod.statusText = '添加文件成功, 等待上传...'
				},
				// 开始上传
				onUploadstarted: function (uploadInfo) {
					// 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
					// 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
					// 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
					// 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
					// 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
					// 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
					if (!uploadInfo.videoId) {
						self.$api.common.aliyunVodFileUpload.getUploadAuthAndAddress
						(
							self.video.courseName,
							uploadInfo.file.name,
							self.video.vod.regionId
						)
						.then(response => {
							if(response.code === '0') {
								let uploadAuth = response.data.uploadAuth
								let uploadAddress = response.data.uploadAddress
								let videoId = response.data.videoId
								uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId)   
							}
							else {
								self.$message.info(response.message)
							}
						})

						self.aliyunVod.statusText = '文件开始上传...'
					} 
					else {
						// 如果videoId有值，根据videoId刷新上传凭证
						// https://help.aliyun.com/document_detail/55408.html?spm=a2c4g.11186623.6.630.BoYYcY
						self.$api.common.aliyunVodFileUpload.refreshUploadAuthAndAddress
						(
							uploadInfo.videoId,
							self.video.vod.regionId
						)
						.then(response => {
							if(response.code === '0') {
								let uploadAuth = response.data.UploadAuth
								let uploadAddress = response.data.UploadAddress
								let videoId = response.data.VideoId
								uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId)
							}
							else {
								self.$message.info(response.message)
								self.aliyunVod.uploadDisabled = true
								self.aliyunVod.pauseDisabled = false
							}
						})
					}
				},
				// 文件上传成功
				onUploadSucceed: function (uploadInfo) {
					self.$api.common.aliyunVodFileUpload.getPlayInfo
					(
						uploadInfo.videoId,
						self.video.vod.regionId
					)
					.then(response => {
						if(response.code === '0') {
							self.video.vod.fileId = response.data.fileId;
							self.video.vod.filePlayUrl = response.data.filePlayUrl;
						}
						else if(response.code === 'serverTransCode') {
							self.video.vod.fileId = response.data.fileId;
							self.video.vod.filePlayUrl = response.data.filePlayUrl;
						}
						else {
							self.$message.info(response.message)
						}
					})
					self.aliyunVod.statusText = '文件上传成功!'
				},
				// 文件上传失败
				onUploadFailed: function (uploadInfo, code, message) {
					self.aliyunVod.statusText = '文件上传失败!'
					self.$message.info('文件上传失败：' + message)
				},
				// 取消文件上传
				onUploadCanceled: function (uploadInfo, code, message) {
					self.aliyunVod.statusText = '文件已暂停上传'
				},
				// 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
				onUploadProgress: function (uploadInfo, totalSize, progress) {
					let progressPercent = Math.ceil(progress * 100)
					self.uploadProcessStatus.video.loadProgress = progressPercent
					self.aliyunVod.statusText = '文件上传中...'
				},
				// 上传凭证超时
				onUploadTokenExpired: function (uploadInfo) {
					// 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
					// 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
					// 然后调用 resumeUploadWithAuth 方法
					self.$api.common.aliyunVodFileUpload.refreshUploadAuthAndAddress
					(
						uploadInfo.videoId,
						self.video.vod.regionId
					)
					.then(response => {
						let uploadAuth = response.data.UploadAuth
						let uploadAddress = response.data.UploadAddress
						let videoId = response.data.VideoId
						uploader.resumeUploadWithAuth(uploadAuth)
					})

					self.aliyunVod.statusText = '文件超时...'
				},
				// 全部文件上传结束
				onUploadEnd: function () {
					self.aliyunVod.statusText = '文件上传完毕'
				}
			})

			return uploader
		},
		handleClose() {
			this.handleResetForm();
            this.$emit("closeEditDialog");
		},
		handleResetForm() {
			this.video = {
				category: {
					id: ''
				},
				courseName: '', //课程名称
				docent: '', //讲师
				cover: //封面
				{
					absolutePath: "",
					relativePath: ""
				},
				vod: //视频
				{
					title: '',
					regionId: 'cn-beijing',
					fileId: '',
					filePlayUrl: ''
				},
				sort: 0
			};

			this.uploadProcessStatus = {
				video: {
					loadProgress: 0
				}
			};

			this.aliyunVod.statusText = '';

			if(!!this.$refs.fileUpload) {
				this.$refs.fileUpload.value = '';
			}
		},
		handleEdit(video) {
			this.video = video
		},
		handleSave() {
			this.$refs.video.validate((valid) => {
                if(valid) {
                    this.$api.aliyun.video.add(this.video)
					.then(response => {
						if(response.code === '0') {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();
						}
						else {
							this.$message.error(response.error)
						}
					})
                }
            });
		},
		handleSaveUpdate() {
			this.$refs.video.validate((valid) => {
                if(valid) {
                    this.$api.aliyun.video.edit(this.video)
					.then(response => {
						if(response.code === "0") {
							this.$message.success('已保存');
							this.handleResetForm();
							this.handleParentRefresh();

							let fileUpload = document.getElementById("fileUpload")
							fileUpload.outerHTML = fileUpload.outerHTML;
						}
						else {
							this.$message.error('保存失败：' + response.message);
						}
					})
                }
            });
		},
		handleParentRefresh() {
			this.$emit("refresh");
		},
		handleFindAllCategory() {
			this.$api.setting.dic.findAll('', 'VIP视频')
			.then(response => {
				if(response.code === "0") {
					this.categoryList = response.data;
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleUploadCover(file) {
			let fileExt = file.name.replace(/.+\./, "")

			if(['jpg', 'jpeg', 'png'].indexOf(fileExt.toLowerCase()) === -1) {
				this.$message.error('上传图片只能是JPG或PNG格式!');
				return;
			}

			this.$api.common.fileUpload.upload(file)
			.then(response => {
				if(response.code === "0") {
					this.video.cover = response.data
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
	},
	mounted() {
		this.handleFindAllCategory()
	}
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
}
.avatar-uploader-icon:hover {
    border-color: #409eff;
}
.avatar {
    width: 178px;
    /* height: 178px; */
    display: block;
    border: 1px dashed #d9d9d9;
}
.avatar:hover {
    border-color: #409eff;
}
.status {
	color: green;
}
</style>