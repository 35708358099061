<template>
	<div class="outside">
		<modal/>
		<toolbar/>
		<app-main/>
	</div>
</template>

<script>
	import Toolbar from "@/layout/outside/toolbar/index";
	import AppMain from "@/layout/outside/appMain/index";
	import Modal from "@/layout/outside/modal";

	export default {
		name: "outside",
		components: {Modal, AppMain, Toolbar}
	}
</script>

<style lang="scss" scoped>
	.outside {
		width: calc(100vw);
		display: flex;
		flex-direction: column;
		background-color: #f0f0f1;
		padding-left: 65px;
	}
</style>
